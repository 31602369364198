import { FILECOLLECTIONNAME } from '../../auth/modules'
import { FileListing, PendingFile } from '../../components/common/files'
import { addFile, removeFile, updateFile } from '../../components/common/files/actions'
import useApi from '../useApi'
import { v4 as uuidv4 } from 'uuid';

export const getFileCollectionName = (id: number) =>
({
    [FILECOLLECTIONNAME.DOCS]: 'Documents',
    [FILECOLLECTIONNAME.PROJECTS]: 'Projects',
    [FILECOLLECTIONNAME.IMPROVEMENTIDEAS]: "ImprovementIdeas",
    [FILECOLLECTIONNAME.IMPROVEMENTACTIVITIES]: "ImprovementActivities",
    [FILECOLLECTIONNAME.GOALS]: "Goals",
    [FILECOLLECTIONNAME.ISSUES]: "Issues",
    [FILECOLLECTIONNAME.ISSUESACCIDENTS]: "IssuesAccidents",

}[id])

const moduleIsHandledByWebforms = (module: string) =>
    [
        'laws',
        'risks',
        'skills',
        'objects-case',
        "supplier-internal",
        "supplier-external",
        "supplier-actionPlan",
        'LevFiler'
    ].includes(module)

const getUrlForUpload = (module: string, collectionId: string) =>
    moduleIsHandledByWebforms(module)
        ? `${window.env.REACT_APP_WEBFORMS_URL}/fileUpload.aspx`
        : `${window.env.REACT_APP_FILE_SERVICE}/api/File/${module}/${collectionId}`

const getFormDataForUpload = (module: string, collectionId: string, pendingFile: PendingFile) => {
    const formData = new FormData()
    formData.append('file', pendingFile.file)
    if (moduleIsHandledByWebforms(module)) {
        formData.append('module', module)
        formData.append('collectionId', collectionId)
    }

    return formData
}

const getFetchUrl = (module: string, collectionId?: string) => {
    if (!collectionId) return undefined

    return moduleIsHandledByWebforms(module)
        ? `${window.env.REACT_APP_WEBFORMS_URL}/fileList.aspx?module=${module}&collectionId=${collectionId}`
        : `${window.env.REACT_APP_FILE_SERVICE}/api/File/${module}/${collectionId}`
}

export const buildLinkToFile = (module: string, id: string, downloadToken?: string) =>
    moduleIsHandledByWebforms(module)
        ? `${window.env.REACT_APP_WEBFORMS_URL}/file.aspx?id=${id}${downloadToken ? `&t=${encodeURIComponent(downloadToken)}` : ``
        }`
        : `${window.env.REACT_APP_FILE_SERVICE ?? ''}/api/file/${id}${downloadToken ? `?t=${encodeURIComponent(downloadToken)}` : ''
        }`

export type OnUploadProgressCallback = (file: PendingFile) => void

export const useFileListing = (
    module: string,
    collectionId?: string,
    onUploadProgress?: OnUploadProgressCallback,
) => {
    const { data, loading, mutate, error, doDelete, post } = useApi<FileListing[]>(
        getFetchUrl(module, collectionId),
    )

    return {
        data: Array.isArray(data) ? data : undefined,
        loading,
        error,
        upload: (pendingFile: PendingFile, collectionIdOverride?: string) => {
            const effectiveCollectionId = collectionIdOverride ?? collectionId!

            return post<FileListing, FormData>(
                getFormDataForUpload(module, effectiveCollectionId, pendingFile),
                getUrlForUpload(module, effectiveCollectionId),
                false,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: event => {
                        pendingFile = {
                            ...pendingFile,
                            status: 'uploading',
                            bytesUploaded: event.loaded,
                        }
                        onUploadProgress?.(pendingFile)
                    },
                },
            ).then(result => {
                if (!result) return

                const existingFile = data?.find(x => x.name === result.name)
                if (existingFile) {
                    existingFile.downloadToken = result?.downloadToken
                    existingFile.id = result?.id
                    mutate(oldFiles => updateFile(oldFiles ?? [], existingFile))
                } else {
                    mutate(oldFiles => addFile(oldFiles ?? [], result))
                }
            })
        },
        deleteFile: (file: FileListing) =>
            (moduleIsHandledByWebforms(module)
                ? post(
                    {},
                    `${window.env.REACT_APP_WEBFORMS_URL}/fileDelete.aspx?id=${file.id}`,
                    false,
                )
                : doDelete(`${window.env.REACT_APP_FILE_SERVICE}/api/File/${file.id}`)
            ).then(() => mutate(oldFiles => removeFile(oldFiles ?? [], file))),
    }
}

export const handleTakePhotoOnIphone = (file: File): File => {
    if (file.name === "image.jpg") {
        const newName = `image-${uuidv4()}.jpg`;
        return new File([file], newName, { type: file.type });
    }

    return file;
}
