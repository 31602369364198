export const sv = {
    "skills": "Kompetens",
    "systematicTasks": "Systematiska uppgifter",
    "issues": "Avvikelser",
    "issuesAccidents": "Olycka/tillbud/riskobservation",
    "objects": "Objekt",
    "improvements": "Förbättringar",
    "chemicals": "Kemikalier",
    "risks": "Risk/Möjlighet",
    "docs": "Dokument/Rutiner",
    "actionsTaken": "Direkt åtgärd",
    "actionsTakenInfo": "Direkt åtgärd (Vad har gjorts för att minimera ytterligare påföljder?)",
    "addNewIssue": "Ny avvikelse",
    "addNewAccident": "Ny olycka/tillbud/riskobservation",
    "mySkills": "Mina kompetenser/utbildningar",
    "switchToMobile": "Byt till mobilläge",
    "screenIsNarrow": "Din skärm verkar ha mobilmått, tryck på knappen för att byta till mobilläge",
    "myTasks": "Mina ärenden",
    "newTask": "Nytt ärende",
    "new": "Ny",
    "signedInAs": "Inloggad som",
    "print": "Skriv ut",
    "validFrom": "Giltig från",
    "validTo": "Giltig till",
    "positions": "Reg. befattningar",
    "position": "Befattning",
    "noSkills": "Inga kompetenser",
    "noPositions": "Inga befattningar",
    "files": "Filer",
    "file": "Fil",
    "addFile": "Ladda upp fil",
    "Bad Request": "Ogilitig begäran",
    "Network Error": "Kommunikationsfel har uppstått",
    "addFirstFile": "Lägg till första filen",
    "heldBy": "Utförare",
    "scope": "Omfattning",
    "plannedDate": "Planerat datum",
    "dateCompleted": "Utfört datum",
    "utvärdera": "Utvärdera",
    "utförd": "Utförd",
    "requiredSkills": "Kompetenskrav",
    "evaluateCourse": "Utvärdera",
    "location": "Ort",
    "save": "Spara",
    "cancel": "Avbryt",
    "reviewCourseText": "Beskriv kortfattat med egna ord hur du upplevde utbildningen. (Utförare, Ort, Omfattning, Innehåll)",
    "satisfiedPercentage": "Ange i % (1-100) hur nöjd är du med aktiviteten/utbildningen? (Där 1 är Inte alls nöjd och 100 är Mycket nöjd)",
    "wouldRecommend": "Skulle du rekommendera denna aktivitet/utbildning till andra?",
    "lessonsLearned": "Vilken är den viktigaste lärdomen som du tar med dig från aktiviteten/utbildningen?",
    "course": "Utbildning",
    "news": "Nyheter",
    "object": "Objekt",
    "readMore": "Läs mer",
    "readLess": "Läs mindre",
    "registerNew": "Registrera ny",
    "analysisAction": "Analys/Åtgärd (utförs av ansvarig)",
    "analysis": "Orsaksanalys",
    "analysisInfo": "Orsaksanalys (Hitta grundorsaken! Använd t.ex. 5 varför metoden)",
    "incidentConditions": "Förhållanden som var som de brukar men som kan ha påverkat händelsen: (Kryssa i alla aktuella)",
    "resolvingAction": "Korrigerande åtgärd",
    "resolvingActionInfo": "Korrigerande åtgärd (Vad ska göras för att detta inte ska ske igen?)",
    "absenceTimeType.-1": "Ej angivet",
    "absenceTimeType.0": "Ingen frånvaro",
    "absenceTimeType.1": "1-3 dagar",
    "absenceTimeType.2": "4-14 dagar",
    "absenceTimeType.3": "Längre frånvaro",
    "absenceTimeType.4": "Dödsfall",
    "feedbackReporter": "Skicka e-post med återkoppling om genomförd åtgärd till den som rapporterat",
    "supplierReply": "Leverantören besvarar",
    "weReply": "Vi besvarar själva",
    "altEmail": "Alternativ e-post",
    "deviationAnswer": "Svarar på avvikelsen",
    "workAuthorityForm": "Arbetsmiljöverkets och Försäkringskassans formulär för anmälan av olyckor, allvarliga tillbud och arbetsskada.",
    "onlyAccident": "Avsnitt för olyckor anpassat för känslig information om den olycksdrabbade. Efter registrering är fälten endast synliga för de som är admin i avvikelsemodulen.",
    "invalidEmail": "Ogiltig e-postadress",
    "employmentType.-1": "Ej angivet",
    "employmentType.0": "Fast anställd",
    "employmentType.1": "Tidsbegränsad anställning",
    "employmentType.2": "Egen företagare/familjemedlem",
    "employmentType.3": "Arbetsmarknadspolitiskt program",
    "employmentType.4": "Studerande (ej praktikant med anställning)",
    "employmentType.5": "Inhyrd personal",
    "causeOfAccident.-1": "",
    "causeOfAccident.0": "Skadad av något annat i rörelse (maskindel, flygande/fallande föremål, kast från handhållen maskin)",
    "causeOfAccident.1": "Hanteringsskada, hanterat föremål (slant med verktyg, stillastående föremål, vass kant)",
    "causeOfAccident.2": "Fall, hopp halkning eller snubbling",
    "causeOfAccident.3": "Feltramp, snedtramp eller spiktramp",
    "causeOfAccident.4": "Brännskada, frätskada, köldskada, förgiftningsskada eller syrebrist",
    "causeOfAccident.5": "Fordonsolycka eller påkörd",
    "causeOfAccident.6": "Fysisk överbelastning",
    "causeOfAccident.7": "Skadad av person (våld) eller djur",
    "causeOfAccident.8": "Elolycka, brand, explosion, sprängning eller gjutning",
    "causeOfAccident.9": "Chock eller annan psykosocial händelse",
    "causeOfAccident.10": "Övriga olyckor",
    "causeOfAccidentShort.-1": "Ej angivet",
    "causeOfAccidentShort.0": "Skadad av något annat i rörelse",
    "causeOfAccidentShort.1": "Hanteringsskada",
    "causeOfAccidentShort.2": "Fall, hopp, halkning",
    "causeOfAccidentShort.3": "Feltramp, snedtramp",
    "causeOfAccidentShort.4": "Brännskada, frätskada",
    "causeOfAccidentShort.5": "Fordonsolycka eller påkörd",
    "causeOfAccidentShort.6": "Fysisk överbelastning",
    "causeOfAccidentShort.7": "Skadad av person, djur (våld)",
    "causeOfAccidentShort.8": "Elolycka, brand",
    "causeOfAccidentShort.9": "Psykosocial händelse",
    "causeOfAccidentShort.10": "Övriga olyckor",
    "explanatoryText.altEmail": "Om du anger en e-postadress skickas en avvikelsehantering till angiven e-postadress istället för den i leverantörsregistret.",
    "placeholder.customerAccident": "Fyll i om det hänt hos kund",
    "notifyClosingIssueActivityResponsible": "Skicka e-post till ansvarig i avvikelse om stängd aktivitet",
    "notifyClosingAccidentActivityResponsible": "Skicka e-post till ansvarig i olycka/tillbud/riskobservation om stängd aktivitet",
    "notifyCreationActivityResponsible": "Skicka e-post till ansvarig om ny aktivitet",

    "deviationSettings.typeMessageHeader": "Ändra typ, felkod och orsakskod",
    "deviationSettings.typeMessageBody": `Det är obligatoriskt att registrera avvikelsetyp för att kunna registrera avvikelser. 
    Underliggande typer (felkod och orsakskod) är frivilliga att använda.  
    För att skapa nya avvikelsetyper används ”+ Lägg till ny”. 
    För att skapa underliggande typer (felkod och orsakskod) klicka på ”+” på typen den ska ligga under. 
    För att visa underliggande typer (felkod och orsakskod) veckla ut avvikelsetypen på ”>” pilen.`,
    "deviationSettings.extraFieldMessageHeader": "Lägg till extrafält",
    "deviationSettings.incidentMessageHeader": "Ändra inställning om anmälan i formulär",
    "deviationSettings.incidentMessageBody": `Här kan du ändra till en egen länk till anmälan och förklarande text. 
    När fälten är tomma är text och länk i formuläret olycka/tillbud/riskobservation VIQMA Systems rekommenderade inställning (Arbetsmiljöverkets och Försäkringskassans formulär för anmälan av olyckor, allvarliga tillbud och arbetsskada. https://anmalarbetsskada.se/).`,

    "deviationSettings.userResposibleMessageHeader": "Ändra inställning om ansvarig och skyddsombud i formulär",
    "deviationSettings.userResposibleMessageBody": `Här kan du ändra eller begränsa vilka användare som ska kunna vara ansvarig i respektive formulär. Du kan också lägga till skyddsombud i formuläret för olycka/tillbud/riskobservation. 
    När en användare är utvald anpassas valbara användare i formulärets rullist för ansvarig eller skyddsombud. Om ingen är vald eller när utvalda raderas kommer alla användare att visas. Fältet för skyddsombud dyker bara upp i formuläret om det finns någon utvald användare.`,
    "deviationSettings.statisticsHeader": "Ändra inställning om statistik på startsidan och arbetskostnad",
    "deviationSettings.statisticsMessageBody": `Här kan du ändra vilken statistik du vill ska vara synlig på startsidan för ditt företag.
     Du kan också ändra arbetskostnaden som används för att räkna ut kvalitetsbristkostnad.`,

    "reportingMessage": "Förklarande text om anmälan",
    "reportingLink": "Länk till anmälan (ska innehålla hela länken, t.ex. https://www.viqma.se/)",
    "deviationSettings.extraFieldMessageBody": `Extrafält är frivilliga att använda. 
    Ange fältnamn och fälttypen som du vill ska visas för detta fält i formuläret och i listvyn för {{value}}. 
    När obligatorisk är ikryssad måste fältet fyllas i för att kunna spara formuläret. 
    Fältnamnet är unikt, om fältet raderas kommer informationen fortfarande vara registrerat mot rubriken om ett fält med samma namn skapas igen.`,
    "popupInfo.deleteDeviationType": "Den här raden kan inte raderas när den används i avvikelse eller har något underliggande",
    "extraField1": "Extrafält1 (Ange Rubrik)",
    "extraField2": "Extrafält2 (Ange Rubrik)",

    "contactSystemAdmin": "Kontakta er systemadmin.",
    "toAddLink": "Klicka här för att lägga till",
    "noIssueTypes": "Ni saknar avvikelsetyper som krävs för att lägga till ny i den här modulen. ",
    "noDepartments": "Ni saknar avdelningar som krävs för att lägga till ny i den här modulen. ",
    "noUsers": "Ni saknar användare som krävs för att lägga till ny i den här modulen. ",
    "noInternalQuestions": "Ni saknar interna frågor som krävs för att lägga till ny i den här modulen. ",
    "noExternalQuestions": "Ni saknar externa frågor som krävs för att lägga till ny i den här modulen. ",

    "collectionId": "Samlings-id",
    "accidentalEradication": "\u00c5tg\u00e4rd vid oavsiktligt utsl\u00e4pp",
    "action": "Handlingsplan",
    "actionEmissions": "\u00c5tg\u00e4rd vid utsl\u00e4pp",
    "addChemicalDepartment": "L\u00e4gg till kemikalier/Avdelning",
    "addChemicalPerDepartment": "L\u00e4gg til Kemikalier per avdelning",
    "addChemicalPerPlaceOfBusiness": "L\u00e4gg till kemikalier per verksamhetsort",
    "addNewRiskText1": "Hur farliga \u00e4r \u00e4mnen i produkten om man uts\u00e4tts f\u00f6r det? (V\u00e4lj ett alternativ.)",
    "addNewRiskText10": "\u00c4mne \u00e4r inte flyktigt eller kan inte bilda damm.",
    "addNewRiskText11": "D\u00e5 sm\u00e5 m\u00e4ngder av \u00e4mnet kan skada samtidigt som det l\u00e4tt kommer upp i luften \u00e4r\n                                                                            \u00e5tg\u00e4rder viktiga \u00e4ven om en liten m\u00e4ngd hanteras. Vid kortvarig hantering av ett\n                                                                            \u00e4mne som inte skadar f\u00f6rr\u00e4n man uts\u00e4tts f\u00f6r stor m\u00e4ngd beh\u00f6ver man oftast inte vidta\n                                                                            s\u00e4rskilda skydds\u00e5tg\u00e4rder, men arbetet ska ordnas s\u00e5 att man inte uts\u00e4tts f\u00f6r \u00e4mnet\n                                                                            i on\u00f6dan.",
    "addNewRiskText12": "G\u00e5 igenom hur \u00e4mnet anv\u00e4nds eller f\u00f6rekommer och svara p\u00e5 f\u00f6ljande:",
    "addNewRiskText13": "Vid vilka arbetsmoment kan arbetstagare uts\u00e4ttas f\u00f6r \u00e5ngor eller damm?\n                                                                                <br>\n                                                                                \u2013 Kan exponeringen under de olika momenten bli s\u00e5 omfattande att man kan skadas\n                                                                                p\u00e5 sikt?\n                                                                                <br>\n                                                                                \u2013 Vilka skydds\u00e5tg\u00e4rder beh\u00f6vs under olika arbetsmoment?\n                                                                                <br>\n                                                                                \u2013 Vilka oplanerade h\u00e4ndelser skulle kunna medf\u00f6ra h\u00f6ga lufthalter?<br>",
    "addNewRiskText14": "Underh\u00e5ll/kontroll\u00e5tg\u00e4rder f\u00f6r att undvika h\u00e4ndelserna:",
    "addNewRiskText15": "Vilka \u00e5tg\u00e4rder, instruktioner och vilken utrustning beh\u00f6vs f\u00f6r att olycksberedskapen\n                                                                                ska vara god?",
    "addNewRiskText16": "Hur farliga \u00e4r \u00e4mnen i produkten om man uts\u00e4tts f\u00f6r det? (V\u00e4lj ett alternativ.)",
    "addNewRiskText17": "Egna kommentarer",
    "addNewRiskText18": "Kan tas upp genom huden och skada andra organ",
    "addNewRiskText19": "Huden skadas direkt (oftast genom att det \u00e4r starkt fr\u00e4tande).",
    "addNewRiskText2": "Ja",
    "addNewRiskText20": "Hudskada uppst\u00e5r om \u00e4mnet f\u00e5r vara kvar p\u00e5 huden.(Om Ja, v\u00e4lj ett alternativ\n                                                                            nedan.)",
    "addNewRiskText21": "Best\u00e5ende skada (till exempel cancer eller allergi).",
    "addNewRiskText22": "\u00d6verg\u00e5ende skada (l\u00e4ker om exponeringen upph\u00f6r).",
    "addNewRiskText23": "Fundera p\u00e5 f\u00f6ljande:",
    "addNewRiskText24": "Vid vilka arbetsmoment kan arbetstagare uts\u00e4ttas f\u00f6r s\u00e5dan hudkontakt eller st\u00e4nk\n                                                                                som kan skada p\u00e5 kort eller l\u00e5ng sikt?\n                                                                                <br>\n                                                                                \u2013 Vilka skydds\u00e5tg\u00e4rder beh\u00f6vs d\u00e5?\n                                                                                <br>\n                                                                                \u2013 Vilka oplanerade h\u00e4ndelser kan medf\u00f6ra st\u00e4nk eller \u00f6versk\u00f6ljning?",
    "addNewRiskText25": "Underh\u00e5ll/kontroll\u00e5tg\u00e4rder f\u00f6r att undvika h\u00e4ndelserna:",
    "addNewRiskText26": "Vilka \u00e5tg\u00e4rder, instruktioner och vilken utrustning beh\u00f6vs f\u00f6r att olycksberedskapen ska vara god?",
    "addNewRiskText27": "F\u00f6rt\u00e4ring",
    "addNewRiskText28": "Hur farliga \u00e4r \u00e4mnen i produkten att f\u00e5 in i munnen?\n                                                                                <br>\n                                                                                (V\u00e4lj ett alternativ.)",
    "addNewRiskText29": "Akut giftigt om man f\u00e5r i sig sm\u00e5 m\u00e4ngder",
    "addNewRiskText3": "Egna kommentarer",
    "addNewRiskText30": "Kan skada p\u00e5 sikt om sp\u00e5rm\u00e4ngder kommer in i munnen via exempelvis fingrar.",
    "addNewRiskText31": "\u00c4mnet \u00e4r ol\u00e4mpligt att f\u00f6rt\u00e4ra. (G\u00e4ller alla farliga \u00e4mnen.)",
    "addNewRiskText32": "Arbetsmetoder som kan medf\u00f6ra f\u00f6rt\u00e4ringsrisk \u00e4r alltid ol\u00e4mpliga. Man b\u00f6r d\u00e4rf\u00f6r\n                                                                            se till att arbetsmetoderna inte inbjuder till att man suger upp v\u00e4tskor eller slickar\n                                                                            p\u00e5 n\u00e5got.",
    "addNewRiskText33": "Om sm\u00e5 m\u00e4ngder kan skada \u00e4r god hygien extra n\u00f6dv\u00e4ndigt.",
    "addNewRiskText34": "Fundera p\u00e5 f\u00f6ljande:",
    "addNewRiskText35": "\u00c4r arbetsmetoden s\u00e5dan att hudkontakt undviks?\n                                                                                <br>\n                                                                                \u2013 H\u00e5ller ni andningsskydd och handskar rena?\n                                                                                <br>\n                                                                                \u2013 Har ni f\u00f6rebyggt spridning av \u00e4mnet till andra lokaler?\n                                                                                <br>\n                                                                                \u2013 Tv\u00e4ttar personalen sig f\u00f6re raster och toalettbes\u00f6k?",
    "addNewRiskText36": "Brandfarlighet/explosionsfara",
    "addNewRiskText37": "Hur brandfarliga eller explosiva \u00e4r \u00e4mnen i produkten? (V\u00e4lj ett alternativ.)",
    "addNewRiskText38": "Mycket eller extremt brandfarliga (flampunkt <23 \u00b0C)",
    "addNewRiskText39": "Brandfarliga (flampunkt >23-60 \u00b0C)",
    "addNewRiskText4": "Produkten skadar redan vid kortvarig exponering f\u00f6r sm\u00e5 m\u00e4ngder.",
    "addNewRiskText40": "Annan klassificering avseende brand-, reaktionseller explosionsrisk",
    "addNewRiskText41": "G\u00e5 igenom hur \u00e4mnet anv\u00e4nds eller f\u00f6rekommer och svara p\u00e5 f\u00f6ljande:",
    "addNewRiskText42": "Kan det finnas \u00e5ngor som kan ant\u00e4ndas eller att det bildas \u00e5ngor n\u00e5gonstans?\n                                                                                <br>\n                                                                                \u2013 Finns det risk f\u00f6r ant\u00e4ndning respektive reaktion?\n                                                                                <br>\n                                                                                &nbsp;&nbsp; - \u00f6ppen eld, heta ytor eller gnistor?\n                                                                                <br>\n                                                                                &nbsp;&nbsp; - andra omst\u00e4ndigheter som kan orsaka reaktionerna?\n                                                                                <br>\n                                                                                \u2013 Vilka f\u00f6r\u00e4ndringar beh\u00f6ver ni g\u00f6ra f\u00f6r att undvika brand/explosion?\n                                                                                <br>\n                                                                                \u2013 Vilka oplanerade h\u00e4ndelser kan medf\u00f6ra brand eller explosion?",
    "addNewRiskText43": "Underh\u00e5ll/kontroll\u00e5tg\u00e4rder f\u00f6r att undvika h\u00e4ndelserna:",
    "addNewRiskText44": "Vilka \u00e5tg\u00e4rder, instruktioner och vilken utrustning beh\u00f6vs f\u00f6r att olycksberedskapen\n                                                                                ska vara god?",
    "addNewRiskText45": "\u00c5tg\u00e4rder vid oavsiktliga utsl\u00e4pp",
    "addNewRiskText46": "Finns instruktioner om hur man ska agera vid oavsiktliga utsl\u00e4pp? (Avsnitt 6 i s\u00e4kerhetsdatabladet)",
    "addNewRiskText47": "Vid spill och l\u00e4ckage ska man f\u00f6rhindra eller minimera de skadliga effekterna p\u00e5 person, egendom och milj\u00f6.",
    "addNewRiskText48": "Fundera p\u00e5 f\u00f6ljande:",
    "addNewRiskText49": "\u2013 Har spillvolymen v\u00e4sentlig betydelse f\u00f6r faran?\n                                                                                <br>\n                                                                                \u2013 Beh\u00f6vs personliga skydds\u00e5tg\u00e4rder eller skyddsutrustning?\n                                                                                <br>\n                                                                                \u2013 Finns instruktion f\u00f6r \u00e5tg\u00e4rder vid n\u00f6dsituation?\n                                                                                <br>\n                                                                                \u2013 Finns behov av milj\u00f6skydds\u00e5tg\u00e4rder, tex. invallning f\u00f6r att f\u00f6rhindra att produkten n\u00e5r avlopp och yt- och grundvatten?\n                                                                                <br>\n                                                                                \u2013 Hur ska spill och sanering hanteras?\n                                                                                 <br>",
    "addNewRiskText5": "Produkten skadar vid l\u00e5ngvarig eller upprepad exponering av sm\u00e5 m\u00e4ngder.",
    "addNewRiskText50": "Andra farliga egenskaper",
    "addNewRiskText51": "Finns det n\u00e5gon annan faroinformation om \u00e4mnen i produkten, t.ex. i punkt 7\n                                                                                eller 10 p\u00e5 s\u00e4kerhetsdatabladet?",
    "addNewRiskText52": "Kan \u00e4mnet reagera h\u00e4ftigt med annat \u00e4mne eller vid vissa f\u00f6rh\u00e5llanden (exempelvis\n                                                                            v\u00e4rme, str\u00e5lning)?",
    "addNewRiskText53": "Kan \u00e4mnet skada vissa material?",
    "addNewRiskText54": "\u2013 Kan egenskaperna utg\u00f6ra en risk i hanteringen?\n                                                                                <br>\n                                                                                \u2013 Vilka f\u00f6r\u00e4ndringar beh\u00f6ver g\u00f6ras?\n                                                                                <br>\n                                                                                \u2013 Finns det oplanerade h\u00e4ndelser som kan f\u00e5 farliga f\u00f6ljder?<br>",
    "addNewRiskText55": "Vilka \u00e5tg\u00e4rder beh\u00f6ver ni g\u00f6ra f\u00f6r att undvika h\u00e4ndelserna:",
    "addNewRiskText56": "\u2013 Vilka \u00e5tg\u00e4rder, instruktioner och vilken utrustning beh\u00f6vs f\u00f6r att olycksberedskapen\n                                                                                ska vara god?",
    "addNewRiskText57": "Sammanfattning",
    "addNewRiskText58": "Sammanlagd riskbed\u00f6mning (med h\u00e4nsyn till ing\u00e5ende \u00e4mnens klassning, koncentration\n                                                                            och m\u00e4ngd)",
    "addNewRiskText59": "Eventuella skydds\u00e5tg\u00e4rder",
    "addNewRiskText6": "Produkten skadar vid exponering f\u00f6r st\u00f6rre m\u00e4ngder.",
    "addNewRiskText60": "Personlig skyddsutrustning kr\u00e4vs",
    "addNewRiskText61": "Ange specifik skyddsutrustning",
    "addNewRiskText62": "Andningsskydd",
    "addNewRiskText63": "Ansiktsskydd",
    "addNewRiskText64": "Skyddsglas\u00f6gon",
    "addNewRiskText65": "Skyddshandskar",
    "addNewRiskText66": "Skyddskl\u00e4der",
    "addNewRiskText67": "Skyddsskor",
    "addNewRiskText68": "Specifik avfallshantering kr\u00e4vs",
    "addNewRiskText69": "Farligt avfall",
    "addNewRiskText7": "Hur l\u00e4tt kommer \u00e4mnen i produkten upp i luften <br> (V\u00e4lj ett alternativ)",
    "addNewRiskText70": "Farligt gods",
    "addNewRiskText71": "Eventuella \u00e4mnen som bildas",
    "addNewRiskText72": "Datum f\u00f6r riskbed\u00f6ming",
    "addNewRiskText73": "Medverkande i riskbed\u00f6mningen",
    "addNewRiskText74": "Hygieniskt gr\u00e4nsv\u00e4rde",
    "addNewRiskText75": "Ett hygieniskt gr\u00e4nsv\u00e4rde (HGV) \u00e4r den h\u00f6gsta godtagbara genomsnittshalten av en luftf\u00f6rorening i inandningsluften. (Avsnitt 8 i s\u00e4kerhetsdatabladet)",
    "addNewRiskText76": "Finns det ett hygieniskt gr\u00e4nsv\u00e4rde?",
    "addNewRiskText77": "Beh\u00f6vs exponeringsm\u00e4tning?",
    "addNewRiskText78": "Om det \u00e4r tydligt att gr\u00e4nsv\u00e4rdet inte \u00f6verskrids, s\u00e5 beh\u00f6ver detta motiveras.",
    "addNewRiskText79": "- Kan exponeringen under olika moment bli s\u00e5 omfattande att man kan skadas p\u00e5 sikt?",
    "addNewRiskText8": "\u00c4mne i produkten \u00e4r mycket flyktigt eller bildar l\u00e4tt dammoln.",
    "addNewRiskText80": "- Vilka oplanerade h\u00e4ndelser skulle kunna medf\u00f6ra h\u00f6ga lufthalter?",
    "addNewRiskText9": "\u00c4mne \u00e4r m\u00e5ttligt flyktigt eller kan avge dammpartiklar som snabbt l\u00e4gger sig.",
    "administrationSP": "f\u00f6rvaltningen(S, P)",
    "airLevel1": "\u00c4mne i produkten \u00e4r mycket flyktigt eller bildar l\u00e4tt dammoln.",
    "airLevel2": "\u00c4mne \u00e4r m\u00e5ttligt flyktigt eller kan avge dammpartiklar som snabbt l\u00e4gger sig.",
    "airLevel3": "\u00c4mne \u00e4r inte flyktigt eller kan inte bilda damm.",
    "areaText": "Anv\u00e4ndningsomr\u00e5de",
    "areSubjectsInLists": "Finns \u00e4mnet med angivna listor?",
    "areThereAnyInstructions": "Finns instruktioner om hur man ska agera vid oavsiktliga utsl\u00e4pp?",
    "attentionToSubject": "Ska \u00e4mnet uppm\u00e4rksammas?",
    "availableAt": "Finns p\u00e5",
    "averageRiskText": "M\u00e5ttlig",
    "browseBtnText": "Bl\u00e4ddra",
    "browseBtnText2": "Bl\u00e4ddra...",
    "cancelBtnText": "\u00c5ngra",
    "candidateList": "Kandidatlistan",
    "captionAir": "Luft",
    "captionAmount": "M\u00e4ngd",
    "captionDesposal": "Avfall",
    "captionFileName": "Filnamn",
    "captionImgLink": "Bildl\u00e4nk",
    "captionUnit": "Enhet",
    "captionUploadedByDate": "Uppladdad den",
    "captionYear": "\u00c5rtal",
    "caustic": "Fr\u00e4tande",
    "chemicalProdText": "F\u00f6rbrukning av kemisk produkt registreras under respektive produkt.",
    "chemicalProduct": "Kemisk produkt",
    "chemicalRiskSource": "Kemiska riskk\u00e4llor",
    "chemicalSubject": "Kemiskt \u00e4mne",
    "chemicalSubjectBr": "Kemiskt <br/> \u00e4mne",
    "chemiDepartmentMatrix": "Matris Kemikalier/Avdelning",
    "classedAsHarmfulGoods": "* Farligt gods",
    "classedAsHarmfulWaste": "* Farligt avfall",
    "clickToExpandRiskAssessment": "Klicka p\u00e5 (>)- tecknet framf\u00f6r produkten f\u00f6r att se hela riskbed\u00f6mningen",
    "closedBy": "Avslutat av",
    "CLP": "CLP-piktogram",
    "commentBrCustomer": "Kommentar <br/> kundkrav",
    "CommentDemand": "Kommentera kundkrav:",
    "confirmDeleteCustomerDemand": "Vill du verkligen radera det h\u00e4r kundkravet?",
    "confirmDeleteDepartmentAssociate": "Vill du verkligen radera den h\u00e4r kopplingen till avdelning?",
    "confirmDeleteFileText": "Vill du verkligen radera den h\u00e4r filen?",
    "confirmDeleteSubject": "\u00c4r du s\u00e4ker p\u00e5 att du vill radera det h\u00e4r \u00e4mnet?",
    "consumption": "F\u00f6rbrukning",
    "consumptionChemical": "F\u00f6rbrukning kemikalie",
    "consumptionChemicals": "F\u00f6rbrukning kemikalier",
    "copyFromRecentStoredSubject": "Kopiera fr\u00e5n tidigare inlagda \u00e4mnen",
    "customerDemandLink": "L\u00e4nk till kundkrav",
    "customerDemands": "Kundkrav",
    "customerText": "Kund",
    "dangerLevel1": "Produkten skadar redan vid kortvarig exponering f\u00f6r sm\u00e5 m\u00e4ngder",
    "dangerLevel2": "Produkten skadar vid l\u00e5ngvarig eller upprepad exponering av sm\u00e5 m\u00e4ngder",
    "dangerLevel3": "Produkten skadar vid exponering f\u00f6r f\u00f6rre m\u00e4ngder",
    "dangerLevelWhenExposured": "Hur farliga \u00e4r \u00e4mnen i produkten om man uts\u00e4tts f\u00f6r det?",
    "dangerMouthLevel1": "Akut giftigt om man f\u00e5r i sig sm\u00e5 m\u00e4ngder",
    "dangerMouthLevel2": "Kan skada p\u00e5 sikt om sp\u00e5rm\u00e4ngder kommer in i munnen via exempelvis fingrar.",
    "dangerMouthLevel3": "\u00c4mnet \u00e4r ol\u00e4mpligt att f\u00f6rt\u00e4ra. (G\u00e4ller alla farliga \u00e4mnen.)",
    "dangerousOnSkin": "Farlig vid-hudkontakt",
    "dangerousToEat": "Farlig att-f\u00f6rt\u00f6ra",
    "dangerousToInHale": "Farlig-att inandas",
    "dangerToPutInMouth": "Hur farliga \u00e4r \u00e4mnen i produkten att f\u00e5 in i munnen?",
    "database": "Anv\u00e4nd ej, inv\u00e4nta uppdatering!",
    "dateOfRiskAssessment": "Riskbed\u00f6mning",
    "dateSDB": "Datum SDB",
    "deactivateProduct": "Inaktivera produkten",
    "declaredContent": "Deklarerat inneh\u00e5ll",
    "declaredContentItem1": "Skriv in de deklarerade ing\u00e5ende \u00e4mnena i produkten. De beh\u00f6vs f\u00f6r att kunna g\u00e5\n                                                                vidare med riskbed\u00f6mning av produkterna. De ing\u00e5ende farliga \u00e4mnena i produkten\n                                                                anges i s\u00e4kerhetsdatabladet under punkt 3. Sammans\u00e4ttning.",
    "declaredContentItem2": "Skriv in de kemiska \u00e4mnen som deklareras p\u00e5 olika rader.",
    "declaredContentItem3": "Skriv in respektive \u00e4mnes kemiska identitetsnummer (CAS eller EINECS (EU-nummer))\n                                                                som g\u00f6r det l\u00e4ttare att s\u00f6ka information om \u00e4mnet.",
    "declaredContentItem4": "Finns inga kemiska namn eller nummer angivna skriv det som anges t.ex. beskrivning\n                                                                av typen av \u00e4mnen. Om inget \u00e4r angivet skriv d\u00e5 \u201dej deklarerat\u201d eller liknande.",
    "declaredContentItem5": "Ange ocks\u00e5 de H-fraser som respektive \u00e4mne klassificerats farligt f\u00f6r.",
    "declaredContentItem6": "Hur mycket inneh\u00e5ller produkten av det enskilda \u00e4mnet? Detta brukar anges i ett\n                                                                procentintervall. Om \u00e4mnena \u00e4r prioriterade eller utpekade \u00e4r det viktigt att f\u00e5\n                                                                en uppfattning om m\u00e4ngden f\u00f6r att g\u00f6ra en riskbed\u00f6mning och v\u00e4lja hur risken ska\n                                                                v\u00e4rderas och hanteras samt hur man ska prioritera de risker man har.",
    "deleteChemicalInfo": "Vill du verklign radera den h\u00e4r kopplingen mot kemikalien?",
    "demandsEmergencyEquipment": "Kr\u00e4ver <br/> n\u00f6dutrustning",
    "demandsSafetyEquipment": "Kr\u00e4ver <br/> skyddsutrustning",
    "demandsSpecificWaste": "Kr\u00e4ver specifik <br/> avfallshantering",
    "departmentConnectionMissing": "Koppling mot <br/> avdelning saknas ",
    "departmentHeadLine": "Avdelning",
    "departmentKemiInfo": "Ange de avdelningar som kemikalien finns p\u00e5",
    "depGroup": "Avdelning/Grupp",
    "disableForThisCompany": "Inaktivera f\u00f6r detta f\u00f6retag",
    "emergencyEquipInfo": "N\u00f6dutrustning <br/> kr\u00e4vs",
    "emergencyEquipment": "N\u00f6dutrustning",
    "emergencyEquipmentText": "* N\u00f6dutrustning",
    "emptySection": "Ingen avdelning",
    "evaluationDateText": "Utv\u00e4rdering datum",
    "evaluationResponsible": "Ansvarig f\u00f6r utv\u00e4rdering",
    "evaluationText": "Utv\u00e4rdering",
    "explosive": "Explosiv.",
    "exportToExcel": "Exportera till Excel",
    "exportToPdf": "Exportera till Pdf",
    "fileAlreadyExists": "Filen finns redan!",
    "fileTypeNotAllowed": "Denna filtyp \u00e4r ej godk\u00e4nd h\u00e4r!",
    "fileUploaded": "Uppladdad fil",
    "fillAPartInfoText": "Fyll i A-delen nedan enligt uppgifter i s\u00e4kerhetsdatablad.",
    "fillBPartInfoText": "Fyll i B-delen nedan. Spara!",
    "fireAndExplosionRisks": "Brandfarlighet/explosionsfara",
    "fireDanger": "Brandfarlig",
    "fireDangerText": "Brandfarlig",
    "fireExplosionLevel1": "Mycket eller extremt brandfarliga (flampunkt <;23 \u00b0C)",
    "fireExplosionLevel2": "Brandfarliga (flampunkt >;23-60 \u00b0C)",
    "fireExplosionLevel3": "Annan klassificering avseende brand-, reaktionseller explosionsrisk",
    "gasUnderPreassure": "Gas under tryck",
    "gasUnderPreassureText": "Gas under tryck",
    "goodEnvironmentalChoice": "Bra Milj\u00f6val!",
    "guidanceForDeclaredContent": "V\u00e4gledning f\u00f6r deklarerat inneh\u00e5ll",
    "guidanceListItem1C": "F\u00f6r \u00e4mnen som b\u00f6r uppm\u00e4rksammas kan en bed\u00f6mning beh\u00f6va g\u00f6ras om \u00e4mnet sl\u00e4pps ut\n                                                                    och i s\u00e5 fall vilken som \u00e4r huvudsaklig utsl\u00e4ppsv\u00e4g. Det handlar i detta l\u00e4get om\n                                                                    en grov uppskattning vilket b\u00e4st g\u00f6rs av verksamhetsut\u00f6varen sj\u00e4lv som har n\u00f6dv\u00e4ndig\n                                                                    kunskap om f\u00f6rh\u00e5llandena i verksamheten. Detta \u00e4r ingenting som beh\u00f6vs g\u00f6ras f\u00f6r\n                                                                    samtliga \u00e4mnen utan fokus b\u00f6r vara p\u00e5 de \u00e4mnen som \u00e4r med i n\u00e5gon lagstiftning i\n                                                                    f\u00f6rsta hand.",
    "guidanceListItem2C": "Om verksamheten hanterar ett \u00e4mnen som \u00e4r utpekade till exempel inom vattenf\u00f6rvaltningen\n                                                                    och detta anv\u00e4nds i en st\u00f6rre omfattning och d\u00e4r det bed\u00f6ms finnas risk f\u00f6r att\n                                                                    \u00e4mnet kan sl\u00e4ppas ut b\u00f6r man ange om utsl\u00e4ppet kan g\u00e5 till reningsverk eller till\n                                                                    recipient.",
    "guidanceReciever": "V\u00e4gledning mottagare",
    "guidanceRisk": "V\u00e4gledning riskbed\u00f6mning",
    "harmful": "Skadlig.",
    "harmfulForSomeMaterials": "\u00c4mnet kan skada vissa material.",
    "hazardous": "Milj\u00f6farlig",
    "hazardousForEnvironMent": "Milj\u00f6farlig",
    "hazardousForEnvironmentText": "Milj\u00f6farlig",
    "hazardousGods": "Farligt <br/> gods",
    "hazardousWaste": "Farligt <br/> avfall",
    "headerSection": "Kemikalier/Avdelning",
    "headlineText": "Rubrik",
    "healthAccusation": "Faroangivelser f\u00f6r h\u00e4lsofaror",
    "highRiskText": "H\u00f6g",
    "HphraseMeaning": "Faro- och skyddsangivelser enl. CLP",
    "HPhrases": "H-fraser",
    "HPhrasesDanger": "Faroangivelser f\u00f6r fysikaliska faror",
    "HPhrasesExplosive": "H201 Explosivt",
    "HPhrasesFarao": "Faroangivelser h-fraser",
    "HPhrasesH202": "H202 Explosivt. Allvarlig fara f\u00f6r splitter och kaststycken",
    "HPhrasesH203": "H203 Explosivt. Fara f\u00f6r brand, tryckv\u00e5g eller splitter och kaststycken",
    "HPhrasesH204": "H204 Fara f\u00f6r brand eller splitter och kaststycken",
    "HPhrasesH205": "H205 Fara f\u00f6r massexplosion vid brand",
    "HPhrasesH220": "H220 Extremt brandfarlig gas",
    "HPhrasesH221": "H221 Brandfarlig gas",
    "HPhrasesH222": "H222 Extremt brandfarlig aerosol",
    "HPhrasesH223": "H223 Brandfarlig aerosol",
    "HPhrasesH224": "H224 Extremt brandfarlig v\u00e4tska och \u00e5nga",
    "HPhrasesH225": "H225 Mycket brandfarlig v\u00e4tska och \u00e5nga",
    "HPhrasesH226": "H226 Brandfarlig v\u00e4tska och \u00e5nga",
    "HPhrasesH228": "H228 Brandfarligt fast \u00e4mne",
    "HPhrasesH240": "H240 Explosivt vid uppv\u00e4rmning",
    "HPhrasesH241": "H241 Brandfarligt eller explosivt vid uppv\u00e4rmning",
    "HPhrasesH242": "H242 Brandfarligt vid uppv\u00e4rmning",
    "HPhrasesH250": "H250 Spontanant\u00e4nder vid kontakt med luft",
    "HPhrasesH251": "H251 Sj\u00e4lvupphettande. Kan b\u00f6rja brinna.",
    "HPhrasesH252": "H252 Sj\u00e4lvupphettande i stora m\u00e4ngder. Kan b\u00f6rja brinna.",
    "HPhrasesH260": "H260 Vid kontakt med vatten utvecklas brandfarliga gaser som kan sj\u00e4lvant\u00e4nda",
    "HPhrasesH261": "H261 Vid kontakt med vatten utvecklas brandfarliga gaser",
    "HPhrasesH270": "H270 Kan orsaka eller intensifiera brand. Oxiderande.",
    "HPhrasesH271": "H271 Kan orsaka brand eller explosion. Starkt oxiderande.",
    "HPhrasesH272": "H272 Kan intensifiera brand. Oxiderande.",
    "HPhrasesH280": "H280 Inneh\u00e5ller gas under tryck. Kan explodera vid uppv\u00e4rmning.",
    "HPhrasesH281": "H281 Inneh\u00e5ller kyld gas. Kan orsaka sv\u00e5ra k\u00f6ldskador.",
    "HPhrasesH290": "H290 Kan vara korrosivt f\u00f6r metaller",
    "HPhrasesH300": "H300 D\u00f6dligt vid f\u00f6rt\u00e4ring",
    "HPhrasesH300H310": "H300 + H310 D\u00f6dligt vid f\u00f6rt\u00e4ring eller vid hudkontakt",
    "HPhrasesH300H330": "H300 + H330 D\u00f6dligt vid f\u00f6rt\u00e4ring eller inandning",
    "HPhrasesH301": "H301 Giftigt vid f\u00f6rt\u00e4ring",
    "HPhrasesH301H311": "H301 + H311 Giftigt vid f\u00f6rt\u00e4ring eller hudkontakt",
    "HPhrasesH301H311H331": "H301 + H311 + H331 Giftigt vid f\u00f6rt\u00e4ring, hudkontakt eller inandning",
    "HPhrasesH301H331": "H301 + H331 Giftigt vid f\u00f6rt\u00e4ring eller inandning",
    "HPhrasesH302": "H302 Skadligt vid f\u00f6rt\u00e4ring",
    "HPhrasesH302H312": "H302 + H312 Skadligt vid f\u00f6rt\u00e4ring eller hudkontakt",
    "HPhrasesH302H312H332": "H302 + H312 + H332 Skadligt vid f\u00f6rt\u00e4ring, hudkontakt eller inandning",
    "HPhrasesH302H332": "H302 + H332 Skadligt vid f\u00f6rt\u00e4ring eller inandning",
    "HPhrasesH304": "H304 Kan vara d\u00f6dligt vid f\u00f6rt\u00e4ring om det kommer ner i luftv\u00e4garna",
    "HPhrasesH310": "H310 D\u00f6dligt vid hudkontakt",
    "HPhrasesH310H310H330": "H310 + H310 + H330 D\u00f6dligt vid f\u00f6rt\u00e4ring, hudkontakt eller inandning",
    "HPhrasesH310H330": "H310 + H330 D\u00f6dligt vid hudkontakt eller inandning",
    "HPhrasesH311": "H311 Giftigt vid hudkontakt",
    "HPhrasesH311H331": "H311 + H331 Giftigt vid hudkontakt eller f\u00f6rt\u00e4ring",
    "HPhrasesH312": "H312 Skadligt vid hudkontakt",
    "HPhrasesH312H332": "H312 + H332 Skadligt vid hudkontakt eller inandning",
    "HPhrasesH314": "H314 Orsakar allvarliga fr\u00e4tskador p\u00e5 hud och \u00f6gon",
    "HPhrasesH315": "H315 Irriterar huden",
    "HPhrasesH317": "H317 Kan orsaka allergisk hudreaktion",
    "HPhrasesH318": "H318 Orsakar allvarliga \u00f6gonskador",
    "HPhrasesH319": "H319 Orsakar allvarlig \u00f6gonirritation",
    "HPhrasesH330": "H330 D\u00f6dligt vid inandning",
    "HPhrasesH331": "H331 Giftigt vid inandning",
    "HPhrasesH332": "H332 Skadligt vid inandning",
    "HPhrasesH334": "H334 Kan orsaka allergi- eller astmasymtom eller andningssv\u00e5righeter vid inandning",
    "HPhrasesH335": "H335 Kan orsaka irritation i luftv\u00e4garna",
    "HPhrasesH336": "H336 Kan g\u00f6ra att man blir d\u00e5sig eller omt\u00f6cknad",
    "HPhrasesH340": "H340 Kan orsaka genetiska defekter",
    "HPhrasesH341": "H341 Misst\u00e4nks kunna orsaka genetiska defekter",
    "HPhrasesH350": "H350 Kan orsaka cancer",
    "HPhrasesH351": "H351 Misst\u00e4nks kunna orsaka cancer",
    "HPhrasesH360": "H360 Kan skada fertiliteten eller det of\u00f6dda barnet",
    "HPhrasesH361": "H361 Misst\u00e4nks kunna skada fertiliteten eller det of\u00f6dda barnet",
    "HPhrasesH362": "H362 Kan skada sp\u00e4dbarn som ammas",
    "HPhrasesH370": "H370 Orsakar organskador",
    "HPhrasesH371": "H371 Kan orsaka organskador",
    "HPhrasesH372": "H372 Orsakar organskador genom l\u00e5ng eller upprepad exponering",
    "HPhrasesH373": "H373 Kan orsaka organskador genom l\u00e5ng eller upprepad exponering",
    "HPhrasesH400": "H400 Mycket giftigt f\u00f6r vattenlevande organismer",
    "HPhrasesH410": "H410 Mycket giftigt f\u00f6r vattenlevande organismer med l\u00e5ngtidseffekter",
    "HPhrasesH411": "H411 Giftigt f\u00f6r vattenlevande organismer med l\u00e5ngtidseffekter",
    "HPhrasesH412": "H412 Skadliga l\u00e5ngtidseffekter f\u00f6r vattenlevande organismer",
    "HPhrasesH413": "H413 Kan ge skadliga l\u00e5ngtidseffekter p\u00e5 vattenlevande organismer",
    "HPhrasesH420": "H420 Skadar folkh\u00e4lsan och milj\u00f6n genom att f\u00f6rst\u00f6ra ozonet i \u00f6vre delen av atmosf\u00e4ren",
    "HPhrasesMassExplosion": "Fara f\u00f6r massexplosion",
    "HPhrasesUnstable": "H200 Instabilt, explosivt",
    "hygienicLimitValue": "Hygieniskt gr\u00e4nsv\u00e4rde",
    "infoTextTopicF": "H\u00e4mta information fr\u00e5n s\u00e4kerhetsdatablad",
    "inSubjects": "A. B. Ing\u00e5ende \u00e4mnen",
    "kemiActionHeadLine": "Handlingsplan",
    "kemiActionShowClosed": "Visa \u00e4ven st\u00e4ngda handlingsplaner",
    "kemiActionSubText": "Om man i riskbed\u00f6mningen har kommit fram till att \u00e5tg\u00e4rder b\u00f6r vidtas, f\u00f6r att eliminera\n                                                    eller minska risken f\u00f6r milj\u00f6 eller h\u00e4lsa, s\u00e5 ska handlingsplan skapas.",
    "kemiActive": "Aktiv",
    "kemiAdminClassA": "Ing\u00e5ende \u00e4mnen och \u00e4mnenas klassificering enl. CLP",
    "kemiAdminClassB": "Ska ing\u00e5ende \u00e4mnen uppm\u00e4rksammas?",
    "kemiAdminClassC": "Vart tar \u00e4mnen v\u00e4gen? Huvudsakliga mottagare",
    "kemiAdminClassD": "\u00c5rlig f\u00f6rbrukning",
    "kemiAdminClassDInformation": "Ange \u00e5rtal, m\u00e4ngd och enhet. Grafen uppdateras om du st\u00e4nger flikarna och \u00f6ppnar\n                                                    igen.",
    "kemiAdminClassE": "S\u00e4kerhetsdatablad",
    "kemiAdminClassF": "Piktogram enligt CLP",
    "kemiAdminClassG": "Riskbed\u00f6mning",
    "kemiAdminClassH": "Handlingsplan",
    "kemiAdminClassI": "Vilken avdelning?",
    "kemiADminClassJ": "Utskrift",
    "kemiAdminHeadLine": "Admin. kemikalier.",
    "kemiAdminInfo": "Anteckna alla kemiska \u00e4mnen och produkter som har en milj\u00f6p\u00e5verkan som ni anv\u00e4nder\n                                i verksamheten. F\u00f6rteckningen ska inneh\u00e5lla alla produkter som \u00e4r m\u00e4rkningspliktiga\n                                f\u00f6r att produkten klassificerats som farlig eller f\u00f6r att den inneh\u00e5ller farliga\n                                \u00e4mnen \u00f6ver en viss halt (alla produkter man f\u00e5tt s\u00e4kerhetsdatablad f\u00f6r!).",
    "kemiAdminInfoInstructions": "F\u00f6r att fylla i A-H, klicka p\u00e5 (>)-tecknet framf\u00f6r knappen \"\u00c4ndra\" (l\u00e4ngst fram p\u00e5 raden)!",
    "kemiAdminProdClass": "Produkt och produktens klassificering enl. CLP",
    "kemiAdminSafetySheet": "Fyll i uppgifter enligt S\u00e4kerhetsblad",
    "kemiCandidateList": "Kandidat <br> listan",
    "kemiCap14": "Reach Kap. 14",
    "kemiCap17": "Reach Kap. 17",
    "kemiCasNr": "CasNr",
    "kemiControledDate": "Kontrollerad",
    "kemiCustomerDemands": "Kundkrav.",
    "kemiDangerClasses": "Faroklasser",
    "kemiDangerDeviation": "Faroangivelser f\u00f6r milj\u00f6faror",
    "kemiDemandsComments": "Kommentar kundkrav.",
    "kemiFraser": "H Fraser",
    "kemiHeaderText": "Ing\u00e5ende \u00e4mnen.",
    "kemiLimitDB": "Begr\u00e4nsnings- <br> databasen <br>- Anv\u00e4nd ej, <br>inv\u00e4nta uppdatering!",
    "kemiMenuItem1": "Admin. Kemikalier",
    "kemiMenuItem2": "Admin. Kemikalie/Avdelning",
    "kemiMenuItem3": "Ing\u00e5ende \u00e4mnen (lista)",
    "kemiMenuItem4": "Hj\u00e4lp",
    "kemiNoSubstanceRegistered": "Inga ing\u00e5ende \u00e4mnen registrerade",
    "kemiPartOfProduct": "Ing\u00e5r i produkten",
    "kemiPrio": "Prio",
    "kemiPrioHeadLine": "PRIO(U,R): <br> Utfasnings- eller Riskminsknings\u00e4mne",
    "kemiShowInactiveProducts": "Visa \u00e4ven inaktiva produkter",
    "kemiSubstance": "\u00c4mne.",
    "kemiSupplier": "Leverant\u00f6r",
    "kemiUse": "Anv\u00e4ndning.",
    "kemiWater": "Vatten- <br> f\u00f6rvaltningen",
    "kindOfDanger": "Typ av farlighet",
    "lastControlledBy": "Senast kontrollerad den",
    "lastUpdatedBy": "Senast uppdaterad",
    "levelOfFireAndExplosion": "Hur brandfarliga eller explosiva \u00e4r \u00e4mnen i produkten?",
    "limit": "Gr\u00e4nsv\u00e4rde",
    "limitation": "Begr\u00e4nsningsdatabasen",
    "limitations": "- Begr\u00e4nsningar",
    "limitDB": "Begr\u00e4nsningsdatabasen - Anv\u00e4nd ej, inv\u00e4nta uppdatering",
    "limitDbShort": "Begr. <br> db.",
    "linkMissing": "L\u00e4nk saknas",
    "linkText": "L\u00e4nk",
    "linkToSafety": "L\u00e4nkade s\u00e4kerhetsdatablad",
    "linkToSafetySheet": "L\u00e4nk till s\u00e4kerhetsdatablad",
    "listItemG1": "Grundl\u00e4ggande \u00e4r att man b\u00f6rjar med att skaffa sig en uppfattning om riskerna med\n                                                                de f\u00f6rekommande farliga kemiska \u00e4mnena s\u00e5 att man f\u00f6rst\u00e5r i vilka situationer de\n                                                                medf\u00f6r skada. Om informationen fr\u00e5n leverant\u00f6ren (normalt s\u00e4kerhetsdatabladet) inte\n                                                                ger denna f\u00f6rst\u00e5else kan det vara n\u00f6dv\u00e4ndigt att tala med den som ansvarar f\u00f6r informationen\n                                                                och beg\u00e4ra f\u00f6rtydliganden.",
    "listItemG2": "Det \u00e4r viktigt att klarg\u00f6ra avgr\u00e4nsningarna f\u00f6r den riskbed\u00f6mning man t\u00e4nker g\u00f6ra,\n                                                                allts\u00e5 vilka typer av risker man har med och vilka arbetsmoment eller lokaler som\n                                                                ing\u00e5r.",
    "listItemG3": "I riskbed\u00f6mning identifieras riskerna f\u00f6r inandning, hudkontakt, f\u00f6rt\u00e4ring, brand-\n                                                                och explosionsfara och \u00f6vriga farliga egenskaper var f\u00f6r sig. Ibland kan samma \u00e5tg\u00e4rd\n                                                                p\u00e5verka flera risker. F\u00f6r att slutligt ta st\u00e4llning till behovet av \u00e5tg\u00e4rder beh\u00f6ver\n                                                                man se \u00f6ver helheten.",
    "listItemToInvestigate1": "\u00c4r \u00e4mnet eventuellt reglerat i Kemikalieinspektionens lagstiftning med begr\u00e4nsningar\n                                                            i anv\u00e4ndningen enligt Begr\u00e4nsningsdatabasen? Om \u00e4mnet \u00e4r begr\u00e4nsat se d\u00e5 \u00f6ver om\n                                                            din anv\u00e4ndning \u00e4r laglig.",
    "listItemToInvestigate2": "Har \u00e4mnet egenskaper som g\u00f6r att det ska utfasas eller \u00e4r ett prioriterat riskminsknings\u00e4mne\n                                                            enligt PRIO-databasen?",
    "listItemToInvestigate3": "Notera med U f\u00f6r utfasnings\u00e4mne och R f\u00f6r riskminsknings\u00e4mne. Det g\u00e5r att s\u00f6ka p\u00e5\n                                                            \u00e4mnen i exempeldatabasen, men det \u00e4r inte rekommenderat att bara g\u00f6ra detta. F\u00f6r\n                                                            att vara s\u00e4ker p\u00e5 om \u00e4mnet tillh\u00f6r kategorierna U eller R b\u00f6r det j\u00e4mf\u00f6ras med egenskaperna\n                                                            under prioguidens \u201dkriterier\u201d.",
    "listItemToInvestigate4": "\u00c4r \u00e4mnet upptaget som ett prioriterat \u00e4mne enligt bilaga 10 i EU:s vattendirektiv?\n                                                            \u00c4mnena \u00e4r utpekade inom EU och har EU-gemensamma riktv\u00e4rden. Vilka \u00e4mnen och \u00e4mnesgrupper\n                                                            det \u00e4r kan hittas i dotterdirektivet om prioriterade \u00e4mnen:",
    "listItemToInvestigate5": "S\u00e4rskilt f\u00f6rorenande \u00e4mnen inom vattenf\u00f6rvaltningen \u00e4r \u00e4mnen som p\u00e5verkar den\n                                                            ekologiska statusen. \u00c4mnena \u00e4r utvalda f\u00f6r att man i Sverige bed\u00f6mer att dessa \u00e4r\n                                                            farliga f\u00f6r vattenmilj\u00f6n och sl\u00e4pps ut i betydande m\u00e4ngd. De \u00e4mnen som man hittills\n                                                            plockat fram riktv\u00e4rden f\u00f6r finns i f\u00f6ljande rapport:",
    "listItemToInvestigate6": "S\u00e4rskilt farliga \u00e4mnen som \u00e4r upptagna p\u00e5 Kandidatlistan f\u00f6r tillst\u00e5nd i REACH.\n                                                            Dessa \u00e4mnen ska bl a deklareras i varor om halten \u00e4r \u00f6ver 0,1 %.",
    "listItemToInvestigate7": "Kontrollera om \u00e4mnena finns upptagna i Reach bilaga 14 (tillst\u00e5nd f\u00f6r s\u00e4rskilt\n                                                            farliga kemikalier),",
    "listItemToInvestigate7LastLine": "eller bilaga 17 (begr\u00e4nsnings\u00e4mnen)",
    "listItemToInvestigate8": "Under respektive l\u00e4nk kan du \u00e4ven l\u00e4sa mer.",
    "listOfCandidate": "Kandidat/listan",
    "listOfCandidates": "Kandidatlistan",
    "listsToInvestigate": "Listor att genoms\u00f6ka",
    "loadingText": "Laddar....",
    "locationOfBusiness": "Verksamhetsort",
    "locationOfBusinessKemiInfo": "Ange de verksamhetsorter som kemikalien finns p\u00e5",
    "locationStorageOccurrence": "Plats f\u00f6rvaring/f\u00f6rekomst",
    "lookThroughSubjects": "G\u00e5 igenom \u00e4mne f\u00f6r \u00e4mne i de olika produkterna och notera:",
    "lowRiskText": "L\u00e5g",
    "mainReciever": "Huvudsakliga mottagare",
    "mandatoryFields": "(Obligatoriska f\u00e4lt",
    "manufacturer": "Tillverkare",
    "measurement": "M\u00e4tning",
    "noChemicals": "Inga kemikalier",
    "noDeclaredSubjects": "Inga deklarerade \u00e4mnen",
    "noRegisteredInfo": "Inga registrerade",
    "noText": "Nej",
    "notifyInfoText": "Vill du verkligen skicka p\u00e5minnelse till ansvarig?",
    "notifyResponsible": "P\u00e5minn ansv.",
    "notSubjectToLabeling": "Ej m\u00e4rkningspliktig",
    "notSubjectToLabelingLong": "Ej m\u00e4rkningspliktig enligt CLP (EG) nr 1272/2008",
    "notSupportedFileType": "Denna filtyp \u00e4r ej godk\u00e4nd h\u00e4r!",
    "noUploadedFileText": "Inga uppladdade filer",
    "openBtnText": "\u00d6ppna",
    "other": "Annan",
    "otherDanger": "Annan fara",
    "otherDangerChar": "Andra farliga egenskaper",
    "otherDangerLevel1": "Kan tas upp genom huden och skada andra organ.",
    "otherDangerLevel2": "Huden skadas direkt (oftast genom att det \u00e4r starkt fr\u00e4tande).",
    "otherDangerLevel3": "Hudskada uppst\u00e5r om \u00e4mnet f\u00e5r vara kvar p\u00e5 huden.",
    "otherDangerLevel4": "Best\u00e5ende skada (till exempel cancer eller allergi).",
    "otherDangerLevel5": "\u00d6verg\u00e5ende skada (l\u00e4ker om exponeringen upph\u00f6r).",
    "otherDangerProperties": "Andra farliga egenskaper?",
    "otherInfoAboutFaror": "Finns det n\u00e5gon annan faroinformation om \u00e4mnen i produkten?",
    "oxid": "Oxiderande",
    "personalPPE": "Personlig <br> skyddsutrustning",
    "personalPPE1": "Andnings- <br>skydd",
    "personalPPE2": "Ansikts- <br>skydd",
    "personalPPE3": "Skydds- <br>glas\u00f6gon",
    "personalPPE4": "Skydds- <br>handskar",
    "personalPPE5": "Skydds- <br>kl\u00e4der",
    "personalPPE6": "Skydds- <br>skor",
    "personalPpeText": "* Personlig skyddsutrustning",
    "piktogramCaption": "Piktogram",
    "possibleSafeGuards": "Eventuella skydds\u00e5tg\u00e4rder",
    "printedDate": "Utskriven den",
    "printHeadLine": "Utskrift",
    "prioDatabase": "PRIO-databas",
    "prioDB": "PRIO-databas <br> (U,R)",
    "prioDbBr": "PRIO- <br> databas",
    "procentInSubject": "Andel (%) <br> i produkten",
    "procentOfSubjectInProduct": "Andel (%) <br> av \u00e4mnet i produkten",
    "productNameText": "Produktens namn",
    "reachBil14": "Reach/ <br> bil. 14",
    "reachBil14PermitDemands": "Reach bil. 14 <br>- Tillst\u00e5ndskrav",
    "reachBil17": "Reach/ <br> bil. 17",
    "reachBil17Limits": "Reach bil. 17 <br> - Begr\u00e4nsningar",
    "rectifiedByTheLatest": "\u00c5tg. senast",
    "responsibleText": "Ansvarig",
    "riskAnalysis": "Riskbedömning",
    "riskAssessmentDate": "Datum f\u00f6r <br> riskbed\u00f6mning",
    "riskFireExplosion": "Brand/Explosion",
    "riskFor": "Risk f\u00f6r",
    "riskForExplosionFire": "Brandfarlig-Explosionsrisk",
    "riskInhale": "Inandning",
    "riskOther": "Annan fara",
    "riskSkinExposure": "Hudkontakt",
    "riskSwallows": "F\u00f6rt\u00e4ring",
    "safetyEquipment": "Skyddsutrustning",
    "safetySheet": "S\u00e4kerhetsdatablad",
    "safetySheetDateText": "Datum p\u00e5 s\u00e4kerhetsdatablad:",
    "saveCustomer": "OBS! Spara kund f\u00f6rst. Ladda upp fil sen.",
    "searchNullText": "Skriv in s\u00f6kord...",
    "showAll": "Visa alla",
    "showChemicalsForSpecDepartment": "Visa kemikalier f\u00f6r specifik avdelning",
    "showChemicalsForSpecLocations": "och/eller f\u00f6r s\u00e4rkild verksamhetsort:",
    "specialRules": "S\u00e4rskilda regler",
    "specificDisposalText": "Specifik avfallshantering",
    "specificWaste": "Specifik avfallshantering",
    "specificWasteDisposal": "Specifik <br> avfallshantering",
    "stateClaim": "- Tillst\u00e5ndskrav",
    "statusText": "Status",
    "subjectContactWithOther": "\u00c4mnet kan reagera h\u00e4ftigt med annat \u00e4mne eller vid vissa f\u00f6rh\u00e5llanden (exempelvis v\u00e4rme, str\u00e5lning).",
    "subjectInAir": "Hur l\u00e4tt kommer \u00e4mnen i produkten upp i luften?",
    "substancesFormed": "\u00c4mnen<br/>som bildas",
    "subTextA": "Ing\u00e5ende \u00e4mnen och \u00e4mnenas klassificering enl. CLP",
    "subTextB": "B. Ladda upp kravdokument eller l\u00e4nka till kundkrav",
    "textTypeAnswer": "Ange svar",
    "toBeDoneAtTheLatest": "Senast \u00e5tg\u00e4rdas den",
    "topicTextA": "A. Deklarerat inneh\u00e5ll",
    "topicTextB": "B. Ska \u00e4mnet uppm\u00e4rksammas?",
    "topicTextC": "C. Huvudsakliga mottagare",
    "topicTextD": "D. F\u00f6rbrukning",
    "topicTextE": "Ladda upp s\u00e4kerhetsdatablad eller l\u00e4nka till leverant\u00f6r",
    "topicTextE2": "E. S\u00e4kerhetsdatablad",
    "topicTextF": "F. Piktogram CLP",
    "topicTextG": "G. Riskbed\u00f6mning",
    "topicTextGInfo": "Alla m\u00e4rkningspliktiga kemikalier och kemitekniska produkter ska riskbed\u00f6mas.",
    "topicTextH": "H. Handlingsplan",
    "topicTextI": "I. Avdelning",
    "topicTextJ": "J. Utskrift",
    "totalRisk": "Risk",
    "totalRiskAssessment": "Sammanlagd riskbed\u00f6mning",
    "Hög": "Hög",
    "Måttlig": "Måttlig",
    "Låg": "Låg",
    "toxic": "Giftig",
    "typeHeadLineInfoText": "Ange rubrik!",
    "typeResponsibleText": "Ange ansvarig!",
    "unhealthy": "H\u00e4lsofarlig",
    "unhealthyText": "H\u00e4lsofarlig",
    "uploadBtnText": "Ladda upp fil",
    "uploadClaimDoc": "Ladda upp kravdokument",
    "uploadedClaimDoc": "Uppladdat kravdokument",
    "uploadedFileInformationText": "Uppladdad fil syns f\u00f6rst n\u00e4r du st\u00e4ngt dialogrutan!",
    "uploadedFiles": "Uppladdade filer",
    "uploadFiles": "Ladda upp fil/filer",
    "uploadFileText": "Ladda upp fil",
    "use": "Anv\u00e4ndning",
    "usedFor": "Anv\u00e4nds till",
    "wasteDisposal": "Avfalls-hantering",
    "water": "Vatten-",
    "waterCleanser": "Vatten <br/> - Reningsverk",
    "waterManagement": "Vatten/F\u00f6rvaltningen",
    "waterManagementHeadLine": "Vatten- <br/> f\u00f6rvaltningen(S,P)",
    "waterManagementHeadLine2": "Vattenf\u00f6rvaltningen (S, P): <br/> S\u00e4rskilt f\u00f6rorenade eller Prioriterat \u00e4mne",
    "waterReciever": "Vatten/ <br/> -Recipient",
    "whereDoSubjectsGo": "Vart tar \u00e4mnen v\u00e4gen?",
    "yesText": "Ja",
    "riskAssesmentDate": "Datum för riskbedömning",
    "dateFrom": "Datum från",
    "dateTo": "Datum till",
    "dateFilter": "Filtrera på datum",
    "filter": "Filtrera",
    "resetFilters": "Återställ sök/gruppering/sortering/filtrering",
    "addNew": "Lägg till ny",
    "topic": "Rubrik",
    "department": "Avdelning/Grupp",
    "responsible": "Ansvarig",
    "responsibleId": "Ansvarig",
    "safetyOfficerId": "Skyddsombud",
    "reporterId": "Rapporterad av",
    "reportedBy": "Rapporterad av",
    "reportedAt": "Rapporterad den",
    "remindResponsible": "Påminn ansvarig",
    "lawRequirement": "Lagar / Krav",
    "status": "Status",
    "activity": "Aktivitet",
    "time": "Tidsåtgång (h)",
    "doneBefore": "Utförd senast",
    "doneBy": "Utförd av",
    "doneDate": "Utförd datum",

    "filename": "Filnamn",
    "fileType": "Filtyp",
    "deleteFile": "Radera fil",
    "endedBy": "Avslutat av",
    "endedDate": "Avslutat den",
    "reviewResponsibleId": "Ansvarig för utvärdering",
    "reviewDate": "Utvärderat den",
    "active": "Aktiv",
    "activeSupplier": "Aktiv leverantör",
    "close": "Stäng",
    "connection": "Koppling",
    "closed": "Stängd",
    "showClosed": "Visa stängda",
    "showSettings": "Visa inställningar",
    "type": "Typ",
    "description": "Beskrivning",
    "descriptionSupplier": "Beskrivning (Delas med leverantören)",
    "descriptionAccident": "Beskrivning (händelseförloppet steg för steg)",
    "lawUpdatedTo": "Uppdaterad tom version",
    "affectedBy": "Det här i lagen berörs vi av",
    "link": "Länk",
    "signout": "Logga ut",
    "company": "Företag",
    "send": "Skicka",
    "sent": "Skickat",
    "sendReminder": "Skicka påminnelse",
    "mailHasBeenSend": "E-post har skickats",
    "template": "Mall",
    "apply": "Applicera",
    "done": "Klar",
    "edit": "Ändra",
    "extraFields": "Extrafält",
    "extraFieldsValue": "Extrafält {{value}}",

    "textLinkReporting": "Text/Länk anmälan",
    "responsible&safety": "Ansvarig/Skyddsombud",
    "safetyOfficer": "Skyddsombud",
    "deviationResponsible": "Avvikelseansvarig",
    "accidentResponsible": "Olycka/Tillbud/Riskobservation ansvarig",

    "required": "Obligatorisk",
    "invalidUrl": "Ogiltig webbadress",
    "noDecimals": "Inga decimaler tillåtna",
    "noNegative": "Inga negativa värden tillåtna",
    "keyMustBeUnique": "Fältnamnen måste vara unika",
    "yes": "Ja",
    "no": "Nej",
    "or": "Eller",
    "name": "Namn",
    "settings": "Inställningar",
    "visibleColumns": "Synliga kolumner",
    "delete": "Radera",
    "showInactive": "Visa inaktiva",
    "inactive": "Inaktiv",
    "dropToGroupBy": "Släpp här för att gruppera på kolumn",
    "dropFileHere": "Dra och släpp en fil här för att lägga till",
    "areYouSure": "Är du säker?",
    "viewMore": "Visa mer",
    "review": "Utvärdering",
    "okey": "Okey",
    "loading": "Laddar",
    "adaptHeightToContent": "Anpassa höjd till innehåll",
    "lastUpdated": "Senast uppdaterad",
    "performedBy": "Utförs av",
    "customer": "Kund",
    "evaluation": "Utvärdering",
    "task": "Uppgift",
    "risksOppertunity": "Risk / Möjlighet",
    "process": "Process",
    "impact": "Påverkan",
    "worth": "Värde",
    "approveSuggestion": "Godkänn förslag",
    "denySuggestion": "Avslå förslag",
    "changedBy": "Inskickat av",
    "addValue": "Registrera ny {{value}}",
    "addMultiValue": "Lägg till en eller flera {{value}}",
    "editValue": "Ändra {{value}}",
    "editValueOwnId": "Ändra {{value}} id: {{ownId}}",
    "totalValue": "Totalt: {{value}}",
    "changedByAndDate": "Förslag skickat den {{date}} av {{name}} ",
    "reviewFor": "Granska ändringsförslag för {{name}}",
    "comment": "Kommentar",
    "commentSupplier": "Kommentar (Delas inte med leverantören)",
    "current": "Nuvarande",
    "changesSaved": "Ändringar har sparats",
    "concern": "Koncern",
    "latest": "Senaste",
    "user": "Användare",
    "users": "Användare",
    "open": "Öppna",
    "add": "Lägg till",
    "acknowledged": "Kvitterad",
    "hidden": "Dold",
    "sorting": "Sortering",
    "copy": "Kopiera",
    "language": "Språk",
    "swedish": "Svenska",
    "english": "Engelska",
    "import": "Importera",
    "workLocation": "Verksamhetsort",
    "issue": "Avvikelse",

    "startDate": "Startdatum",
    "endDate": "Slutdatum",
    "saved": "Sparat",
    "notificationsSend": "Antal skickade:",
    "of": "av",
    "standard": "Standard",

    "module": "Modul",
    "created": "Skapad",
    "deleted": "Borttagen",
    "restore": "Återställ",
    "userFromAnotherCompany": "Användare från annat företag",
    "routine": "Rutin",
    "routines": "Rutiner",
    "version": "Version",
    "concernDocuments": "Koncernrutiner",
    "documents": "Dokument",
    "archiveSite": "Arkivplats",
    "archiveTime": "Arkiveringstid",
    "risksOppertunities": "Risker / Möjligheter",
    "responsiblePosition": "Ansvarig befattning",
    "responsiblePositionConcern": "Ansvarig befattning (Koncern)",
    "configure responsible": "Ändra ansvarig befattning",
    "review suggestion": "Granska ändringsförslag",
    "submit suggestion": "Lämna ändringsförslag",
    "documents stored in other location": "Dokument som lagras på annan plats än i systemet",
    "sendForApproval": "Skicka för godkännande",
    "sendTo": "Skicka till",
    "hasBeenSend": "Ändringsförslaget är nu skickad",
    "commentYourChanges": "Kommentera gärna dina ändringar",
    "confirmDocumentRead": "En adminstratör önskar återkoppling på att du läst denna rutin. Vänligen bekräfta detta på knappen till höger",
    "markAsViewed": "Markera som läst",
    "activityProcessService": "Aktivitet/Produkt/Tjänst",
    "thisIsACopyCheckOriginal": "Detta är en kopia. Kontrollera utskrift mot original i VIQMA System!",
    "routineIsOnRemiss": "Rutinen är på remiss",
    "compareAndDisplayVersions": "Visa och jämför versioner",
    "compareFrom": "Jämför från",
    "compareTo": "Jämför till",
    "addExternalDocument": "Lägg till dokument som lagras på annan plats än i systemet",
    "directApproval": "Godkänn direkt",
    "documentBelongsTo": "Rutinen kommer från: {{name}}",
    "selectReadConfirmationCase": "Välj ett kvittensärende i tabellen till vänster för att visa det här",
    "skillPositions": "Befattningar",
    "createReadConfirmationCase": "Skapa kvittensärende",
    "addResponsiblePositions": "Lägg till befattningar",
    "archiveReportedDocs": "Redovisande dokument",
    "sortOrder": "Nummerordning: (För sortering, annars bokstavsordning)",
    "adminDocument": "Administrera rutin",
    "dropHereToMoveToRoot": "Släpp här för att flytta till i roten",
    "governingProcess": "Ledningsprocess",
    "mainProcess": "Huvudprocess",
    "supportProcess": "Stödprocess",
    "updatedTemplateRoutinesInLast12Months": "Uppdaterade VIQMA Mallrutiner (senaste 12 månaderna)",
    "notSharedToSubCompanies": "Visas ej på enheter/underliggande företag",
    "saveAsVersion1": "Spara som version 1 och radera alla tidigare versioner",
    "sureYouWantToDeleteDocument": "Vill du verkligen radera rutinen och alla kopplade filer? Det innebär alla ev. uppladdade filer, redovisande dok. i arkiveringstabellen, tidigare versioner av rutinen och kopplingar till lagar",
    "cantDeleteRoutineWithChildren": "Kan ej tas bort då rutinen har underliggande rutiner",
    "hidingDocumentWillAffectChildren": "Om du döljer denna rutin kommer det även dölja alla underliggande rutiner",
    "useButtonToImportRoutines": "Använd knappen till höger för att importera en eller flera rutiner från mallföretag.",
    "copyFunction": "Kopieringsfunktion",
    "suggestedLawConnections": "Förslag på lagkoppling",
    "addLawConnection": "Lägg till lagkoppling",
    "editorIsReadOnlyClickToActivate": "Editorn är skrivskyddad, tryck för att låsa upp och tillåta ändringar i rutinen",
    "error": "Error",
    "notAllowedToApproveDocument": "Du är inte rätt person att godkänna detta dokument",
    "documentBelongToAnotherCompany": "Dokumentet tillhör ett annat företag",
    "goalsHeader": "Mål",
    "addDecision": "Lägg till Uppföljning/Beslut",
    "updateDecision": "Uppdatera Uppföljning/Beslut",
    "occasion": "Tillfälle",
    "decisions": "Uppföljningar/Beslut",
    "decision": "Uppföljning/Beslut",
    "connectedMeasurement": "Kopplat mätvärde",
    "start": "Start",
    "targetValue": "Målvärde",
    "addActivity": "Lägg till aktivitet",
    "updateActivity": "Ändra aktivitet",
    "numDecisions": "Antal Uppföljning/Beslut",
    "numActivities": "Antal aktiva aktiviteter",
    "startValue": "Första värde",
    "currentValue": "Nuvarande värde",
    "chooseExistingOrCreate": "Välj ett existerande mätvärde eller skapa",
    "newMeasurement": "Nytt mätvärde",
    "newMeasurementValue": "Ny mätning",
    "detailedType": "Detaljerad typ",
    "unit": "Enhet",
    "firstMeasurement": "Första mätning",
    "period": "Period",
    "yearly": "Årvis",
    "monthly": "Månadvis",
    "weekly": "Veckovis",
    "week": "Vecka",
    "value": "Värde",
    "newSubGoal": "Nytt delmål",
    "goalIsKpi": "Målet är ett nyckeltal",
    "restructureGoals": "Omstrukturera mål",
    "restructure": "Omstrukturera",
    "restructureGoalsInfoText": "Här kan du omstrukturera hur målen hänger ihop. Ett delmål kan flyttas till ett annat huvudmål eller dras till roten för att bli ett eget mål. Observera att huvudmål med delmål inte kan flyttas",
    "forbiddenOperationIsAChild": "Förbjuden åtgärd, valt mål är ett undermål",
    "forbiddenOperationHasChildren": "Förbjuden åtgärd, valt mål har undermål",
    "forbiddenOperationSelfParentingNotAllowed": "Förbjuden åtgärd, ett mål kan inte vara sitt eget undermål",
    "cantCloseGoalIfThereAreOpenChildren": "För att avsluta ett överliggande mål avsluta först underliggande mål eller omstrukturera",
    "useFirstValue": "Ange ett första mätvärde",
    "measurementValues": "Mätvärden",
    "editMeasurement": "Ändra mätning",
    "cantDeleteGoalWithChildren": "Kan ej tas bort då målet har underliggande delmål",
    "sureYouWantToDeleteGoal": "Är du säker på att du vill ta bort målet?",
    "alsoShowClosed": "Visa även stängda",
    "displayDataAs": "Visa data som",
    "lineDiagram": "Linjediagram",
    "barDiagram": "Stapeldiagram",
    "stackedBarDiagram": "Stackat stapeldiagram",
    "pieDiagram": "Pajdiagram",
    "goal": "Mål",
    "idea": "Idé",
    "expectedResult": "Förväntat resultat",
    "suggestion": "Lösningsförslag",
    "groupId": "Grupp",
    "issueType": "Avvikelsetyp",
    "errorCode": "Felkod",
    "sendEmailToResponsible": "Skicka e-post till ansvarig",
    "sendEmailToSafetyOfficer": "Skicka e-post till skyddsombud",
    "sendDeviationManagementEmail": "Skicka e-post med länk till avvikelsehantering",
    "sendDeviationManagementEmailChecked": "Om ikryssad, kommer det skickas ett e-post meddelande till leverantören eller alternativ e-post när du sparar.",
    "specialProject": "Specialprojekt",
    "causeCode": "Orsakskod",
    "orderNumber": "Ordernummer",
    "isReported": "Anmäld",
    "incidentIsReported": "Händelsen anmäld",
    "accidentDate": "Olycksdatum",
    "accidentTime": "Tidpunkt",
    "optionalNameOfTheInjuredPerson": "Namn på den skadade: (Valfritt)",
    "employmentType": "Anställningsform",
    "employmentYear": "Anställningstid (antal år)",
    "whatHappened": "Orsak till olycka",
    "factor": "Faktor",
    "differencesFromNormal": "Vad var tillfälligt annorlunda än det brukar? Varför hände det?",
    "conditionalEffects": "Notering",
    "locationHadImpact": "T.ex. belysning, sikt, värme, kyla, buller, arbetsställningar, underlagets beskaffenhet, halka, städning, ordning",
    "equipmentHadImpact": "T.ex. oskyddade maskinrörelser, sliten utrustning, fel verktyg, hjälpmedel saknas, användning av personlig skyddsutrustning, olämpliga skor/kläder/handskar",
    "workEnvironmentHadImpact": "T.ex. ständig tidspress, produktionsplanering, ackordslön, övertid, rutinarbete, inga buffertar, problem med frånvaro, samarbetsproblem",
    "preventiveActionsHadImpact": "T.ex. risken kunde ha upptäckts och undanröjts vid riskinventering, skyddsronder, underhåll",
    "companyClimateHadImpact": "T.ex. konflikt produktionssäkerhet, arbets-/säkerhetsinstruktioner, rutiner för arbetet saknas, kunskap om tillbud eller avvikelser har inte rapporterats eller åtgärdats",
    "educationHadImpact": "T.ex. person saknade kompetens, introduktion, internutbildning, arbetsplatsmöten, information hade ej nått arbetstagare eller chef, eller rutiner och instruktioner följdes inte",
    "otherCausesHadImpact": "Andra förhållanden, notera vad",
    "estimatedAbsenceTime": "Trolig sjukfrånvaro",
    "whereDidTheIncidentOccur": "Var inträffade händelsen? (I byggnad, maskin, fordon etc.)",
    "whatWasTheActualTaskWhenTheIncidentOccured": "Vilket var det aktuella arbetsmomentet då händelsen inträffade?",
    "ifPersonalInjuryWhatCausedIt": "Vid personskada: Vad skadade sig personen på?",

    "couldHaveHadeAffect": "Förhållanden som var som de brukar men som kan ha påverkat händelsen",
    "checkAllThatApply": "Kryssa i alla aktuella",
    "locationWhereItHappened": "Platsen där det hände",
    "equipmentAndClothes": "Utrustning, kläder",
    "workSituation": "Arbetssituationen",
    "preventiveActions": "Förebyggande arbetet",
    "securityClimate": "Säkerhetsklimat",
    "educationAndInformation": "Utbildning och information",
    "otherWriteBelow": "Annat, skriv vad",
    "incident": "Tillbud",
    "riskObservation": "Riskobservation",
    "heading": "Rubrik",
    "projects": "Projekt",
    "goals": "Mål/Mät",
    "laws": "Lagar/Krav",
    "suppliers": "Leverantörer",
    "adminSuppliers": "Admin. Leverantörer",

    "surveys": "Undersökningar",
    "statistics": "Statistik",
    "valueStatistics": "{{value}} statistik",

    "perform": "Utför",
    "handleCase": "Hantera ärende",
    "priority": "Prio",
    "estimatedTime": "Uppskattad tid",
    "noCases": "Du har inga ärenden att hantera",
    "actualTime": "Verklig tid",
    "materialCost": "Materialkostnad",
    "laborCost": "Arbetskostnad",
    "externalActor": "Extern utförare",
    "externalActorTime": "Tid extern tjänst",
    "externalActorCostPerHour": "Kostnad/tim extern tjänst",
    "high": "Hög",
    "medium": "Mellan",
    "low": "Låg",
    "sendMailToHandler": "Skicka e-post till utförare",
    "case": "Ärende",
    "measurementDevice": "Mätdon",
    "result": "Åtgärd/Resultat",
    "instruction": "Instruktion/Uppdrag",
    "projectStart": "Projektstart",
    "projectEnd": "Projektslut",
    "projectDescription": "Beskrivning (bakgrund/nuläge)",
    "projectGoal": "Mål med projektet",
    "project": "Projekt",
    "projectActivity": "Projektaktivitet",
    "projectPlan": "Projektplan",
    "implementation": "Genomförande",
    "endProject": "Avsluta projekt",
    "sendAsAppointment": "Skicka som uppgift till min kalender i outlook",
    "sendMailToResponsible": "Skicka e-post till ansvarig",
    "useProjectTemplate": "Använd en projektmall",
    "projectTemplateCanDo": "En projektmall kan lägga till egna fält samt förskapa aktiviteter i projektet",
    "templateApplied": "Mallen applicerades",
    "projectTemplateAdminIntroText": "Här kan du skapa nya projektmallar eller ändra existerande. För att ända välj en i tabellen nedan eller tryck på \"Lägg till ny\" för att skapa en ny.",
    "fieldName": "Fältnamn",
    /* eslint-disable no-useless-escape */
    "fieldNameNoChar": `Fältnamn, otillåtna specialtecken (."'[]\:/;,&_)`,
    "fieldType": "Fälttyp",
    "fieldType.0": "Text",
    "fieldType.4": "Flera användare",
    "number": "Nummer",
    "string": "Text",
    "lawInfo": "Laginfo",
    "date": "Datum",
    "approvedBy": "Godkänd av",
    "userDropdown": "Användare",
    "multipleUserDropdown": "Flera användare",
    "useActivitiesAsChecklist": "Använd aktiviteter som checklista",
    "projectList": "Projektlista",
    "projectTemplate": "Projektmall",
    "projectTemplateList": "Projektmallar",
    "adaptHeightToWindow": "Anpassa höjd till fönster",
    "searchPage": "Söksida",
    "searchPhrase": "Sökord",
    "filterResponsiblePosition": "Filtrera på ansvarig befattning",
    "noSearchHits": "Sökningen gav inga träffar",
    "sharedRoutines": "Delade rutiner",
    "sharedUploadedFiles": "Delade uppladdade filer",
    "accidentUploadedFiles": "Uppladdade filer olycka/tillbud/riskobservation",
    "certexUploadedFiles": "Uppladdade filer certex",
    "projectFiles": "Projektfiler",
    "nordProFiles": "Projektfiler - Nordshakt",
    "osterlingsProFiles": "Projektfiler - Österlings",
    "viqmaProFiles": "Filer - Prospekt",
    "specProFiles": "Filer - Specialprojekt",
    "objectFiles": "Objekt - Uppladdade filer",
    "objectCaseFiles": "Objekt Ärende - Uppladdade filer",
    "objectRiskFiles": "Risk Objekt - Uppladdade filer",
    "objectRiskActionPlanFiles": "Risk Objekt - handlingsplan filer",
    "calibrationFiles": "Kalibrering - Uppladdade filer",
    "accidentIncidentRisk": "Olycka/Tillbud/Riskobservation",
    "goalUploadedFiles": "Mål - Uppladdade filer",
    "skillsUploadedFiles": "Kompetens - Uppladdade filer",
    "issueUploadedFiles": "Uppladdade filer avvikelser",
    "externalUploadedFiles": "Leverantörer – Externa uppladdade filer",
    "internalUploadedFiles": "Leverantörer – Interna uppladdade filer",
    "riskUploadedFiles": "Risk/möjlighet - Uppladdade filer",
    "riskHplFiles": "Risk/möjlighet handlingsplan filer",
    "chemicalsUploadedFiles": "Kemikalier - Uppladdade filer",
    "improvementsUploadedFiles": "Förbättringar - Uppladdade filer",
    "systematicTasksUploadedFiles": "Systematiska uppgifter - Uppladdade filer",
    "documentsUploadedFiles": "Rutiner - Uppladdade filer",
    "sharedDocumentsUploadedFiles": "Delade rutiner - Uppladdade filer",

    "plannedCompletedEducations": "Planerade/Utförda Utbildningar",
    "education": "Education",

    "pendingDocumentReadConfirmations": "Rutiner på remiss",
    "documentsUpdatedThisMonth": "Rutiner uppdaterade senaste månaden",
    "deviations": "Avvikelser",
    "deviation": "Avvikelse",
    "deviationsCertex": "Avvikelser",
    "deviationsSkog": "Avvikelser",
    "educationLiftetime": "Kompetens - Utbildningars giltighetstid",
    "skill": "Kompetens",
    "goalActivities": "Målaktiviteter",
    "updatedLaws": "Uppdaterade lagar",
    "invitedParticpants": "Inbjuden som deltagare till Systematisk uppgift",
    "particpants": "Deltagare",
    "teamMemberId": "Team-medlem",
    "deviationTeamMembers": "Inbjuden som team-medlem till avvikelse",

    "outcome": "Utfall",
    "issueReview": "Utvärdering av Avvikelser",
    "activities": "Aktiviteter",
    "emergency": "Nödläge",
    "newSuppliers": "Nya leverantörer",
    "supplierActions": "Leverantörer - Åtgärder",
    "supplierActionsApi": "Leverantörer - Åtgärder",
    "supplierCertificate": "Leverantörer - Certifikat giltigt t.o.m",
    "supplierCertificateApi": "Leverantörer - Certifikat giltigt t.o.m",
    "supplierNotAnswered": "Leverantörer - Som ej svarat på frågorna",
    "supplierNotAnsweredApi": "Leverantörer - Som ej svarat på frågorna",
    "supplierEvaluated": "Leverantörer - Internbedömning",
    "supplierEvaluatedApi": "Leverantörer - Internbedömning",
    "supplieDeliverStatistic": "Leverantörer - Leveransstatistik",
    "tasks": "Ärenden",
    "objectTasks": "Ärenden",
    "objekt": "Objekt",
    "category": "Kategori",
    "riskGoalsPlanes": "Riskbedömning - Handlingsplaner",
    "evaluationRiskPlanes": "Riskbedömning - Utvärdering  av Handlingsplaner",
    "month": "Månad",
    "year": "År",
    "updatedDesignation": "Aktuell Uppd.",
    "supplier": "Leverantör",
    "certKv": "Q-cert",
    "certMi": "M-cert",
    "certAr": "A-cert",
    "verCSR": "CSR-ver",
    "certOv": "Övrigt cert",
    "iso3834": "3834-cert",
    "iso1090": "1090-cert",
    "noNews": "Det finns inga aktiva nyheter just nu",
    "ownId": "Id",
    "reportedDate": "Rapporterad den",
    "acknowledgeReadingDocuments": "Kvittera läsning av rutin",
    "risk": "Risk",
    "deviationActivities": "Avvikelse-aktiviteter",
    "deviationActivitiesCertex": "Avvikelse-aktiviteter",
    "deviationActivitiesSkog": "Avvikelse-aktiviteter",
    "evaluationDeviations": "Utvärdering  av Avvikelser",
    "evaluationDeviationsCertex": "Utvärdering  av Avvikelser",
    "evaluationDeviationsSkog": "Utvärdering  av Avvikelser",
    "areYouSureYouWishToSendReminder": "Är du säker på att du vill skicka en påminnelse?",
    "all": "Alla",
    "denomination": "Benämning",
    "designation": "Beteckning",
    "applicableFrom": "Träder i kraft",
    "dateSent": "Skickad den",
    "systematicTasksIssues": "Avvikelser i systematiska uppgifter",
    "removeConnection": "Ta bort koppling",
    "viqmaProjectActivity": "Kundprojekt",
    "accidentIncident": "Olycka/Tillbud/Riskobservation",
    "accidentSafetyOfficer": "Olycka/Tillbud/Riskobservation - Skyddsombud",
    "courseReviewWaiting": "OBS! Du har en utförd utbildning som ligger för utvärdering.",
    "goCompetence": "Klicka här för att komma till Kompetens/Utbildning och utvärderingen!",
    "goCalibration": "Klicka här för att komma till kalibreringen!",
    "calibrationWaiting": "OBS! Det finns verktyg som behöver kalibreras!",
    "deviationsPerYear": "Avvikelse/år",
    "deviationsPerMonth": "Avvikelse/Månad",
    "effiencyPerMonth": "Effektivitet/Månad",
    "averageDaysUntilResolve": "Medelvärde antal dagar till åtgärd",
    "accidentsPerYear": "Olyckor/år",
    "issueCostPerMonth": "Kvalitetsbristkostnad",
    "cost": "Kostnad (kr)",

    "improvementIdeasPerYear": "Förbättringsideér/År",
    "improvementIdeasPerMonth": "Förbättringsideér/Månad",
    "improvementsPerYear": "Förbättringsaktiviteter/År",
    "improvementsPerMonth": "Förbättringsaktiviteter/Månad",
    "improvementEffecieny": "Åtgärdseffektivitet förbättringar",
    "riskActionPlans": "Risk/Möjlighet - Handlingsplaner",
    "accidentActivities": "Olycka/Tillbud/Riskobservation - Aktiviteter",
    "filesAddedThisMonth": "Filer tillagda den här månaden",
    "concernFilesAddedThisMonth": "Koncernfiler tillagda den här månaden",
    "routineRemiss": "Rutiner på remiss",
    "activityTools": "Aktiviteter för mätverktyg",
    "evaluationAccidents": "Utvärdering av Olycka/Tillbud/Riskobservation",
    "nordProjectActivity": "Specialprojekt-aktiviteter",
    "ostProjectActivity": "Projekt-aktiviteter (Bygg)",
    "specProjectActivity": "Specialprojekt",
    "chemicalsActions": "Handlingsplaner för kemikalier",
    "product": "Produkt",
    "activityImprovments": "Förbättringsaktiviteter",
    "ideasImprovments": "Förbättringsidéer",
    "improvementIdea": "Förbättringsidé",
    "improvementIdeas": "Förbättringsidéer",
    "improvementActivity": "Förbättringsaktivitet",
    "improvementActivities": "Förbättringsaktiviteter",
    "improvementGroups": "Förbättringsgrupper",
    "group": "Grupp",
    "todo": "Åtgärda",
    "concernDocumentsUpdatedThisMonth": "Koncernrutiner uppdaterade senaste månaden",
    "reviewBefore": "Uppföljning senast",
    "updatedTemplateDocuments": "Uppdaterade VIQMA Mallrutiner (senaste 3 månaderna)",
    "updatedTemplateFiles": "Uppdaterade VIQMA Mallfiler",
    "belongToRoutine": "Tillhör rutin",
    "standby": "Avvaktar beslut",
    "decisionMade": "Beslut fattat",
    "viqmaProspekts": "Prospekt",
    "contactDate": "Kontakta(datum)",
    "demoDate": "Demo(datum)",
    "sentBy": "Skickad av",

    "accidentReason.0": "Olycka",
    "accidentReason.1": "Tillbud",
    "accidentReason.2": "Riskobservation",
    "question": "Fråga",
    "previous": "Föregående",
    "next": "Nästa",
    "email": "Email",
    "mobileSystematicTasksHeader": "Mobila systematiska uppgifter",
    "newMobileSystematicTasksHeader": "Ny mobil systematisk uppgift",
    "mobileSystematicTasksIntroText1": "Här kan du skapa nya samt ändra befintliga mallar för Systematiska uppgifter som ska hanteras i mobilen. Klicka på “Lägg till ny” för att bygga en ny mall (checklista) för t.ex. Brand- och skyddsrond och koppla mot en systematisk uppgift. Det går endast att skapa en mall per systematisk uppgift.",
    "mobileSystematicTasksIntroText2": "Ansvariga för uppgiften får upp den på sin startsida i mobilversionen en månad innan nästa uppgift är planerad. När det mobila formuläret är ifyllt och sparas stängs uppgiftsdatumet, en PDF skapas av det ifyllda formuläret och laddas upp under uppgiften (filnamnet blir automatiskt ”namn på systematisk uppgift” + ”uppgiftens planerade datum” och finns under fliken uppladdade filer).",
    "editMobileSystematicTasksHeader": "Redigera mobil systematisk uppgift",
    "responsibleForTemplate": "Ansvarig för mall",
    "linkToSystematicTask": "Koppling till systematisk uppgift",
    "systematicTaskInformationInput": "Infoga information",
    "checkboxWithComment": "Kryssruta med kommentar",
    "fieldsForMobileForm": "Fält för mobilt formulär",
    "addFieldForMobileForm": "Lägg till nytt fält",
    "editFieldForMobileForm": "Redigera fält",
    "addField": "Lägg till fält",
    "systematicTask": "Systematisk uppgift",
    "fillInAllRequiredFields": "Fyll i alla obligatoriska fält!",
    "dueDate": "Senast åtgärdas den",
    "checkList": "Checklista",
    "taskDate": "Uppgiftsdatum",
    "functions": "Funktioner",
    "confirm.deleteValue": "Är du säker på du vill radera {{value}}?",
    "confirm.supplierLink": "Vill du verkligen skicka iväg en extern leverantörs utvärdering?",
    "donePercentage": "Klar i %",
    "decisionStatus": "Beslutsstatus",
    "finishedAt": "Avslutat den",
    "plannedFinishedAt": "Planerat klart den",
    "difficultyCost": "Svårighetsgrad/Kostnad",
    "importanceValue": "Effekt/Värde",
    "improvementDecision": "Beslut",
    "decisionResult": "Resultat/Beslut",
    "evaluationRequired": "Använd utvärdering",
    "useReview": "Använd utvärdering",
    "today": "Idag",
    "activateIdea": "Aktivera idé till aktivitet",
    "priorityInfo": "Klicka i prioritetsmatrisen för att välja eller ändra prioritet på aktiviteten.",
    "evaluationResult": "Utvärdering (Har åtgärder gett önskat resultat?)",
    "reviewText": "Utvärdering",
    "evaluatedBy": "Utvärderat av",
    "evaluatedAt": "Utvärderat den",
    "isUsedDeviation": "Används i avvikelse",
    "isUsed": "Används",
    "issueHierarchyType": "Typ",
    "issueHierarchyType.0": "Avvikelsetyp",
    "issueHierarchyType.1": "Felkod",
    "issueHierarchyType.2": "Orsakskod",

    "notifyAuthorizedUser": "Skicka e-post till användare med befogenhet",
    "authorizedUser": "Användare med befogenhet",
    "closeImprovementActivity": "Avsluta förbättringsaktivitet",
    "closeDeviation": "Avsluta avvikelse",
    "closeAccident": "Avsluta olycka/tillbud/riskobservation",

    "page": "Sida",
    "pageSize": "Antal per sida",
    "solutionProposal": "Lösningsförslag",
    "disabledResponsibleReminder": "Ansvarig måste vara inlagd och aktiv användare för att skicka påminelse",
    "disabledSendSupplierLink": "Leverantören måste ha en giltig e-postadress inlagd i sina kontaktuppgifter.",
    "sendSupplierLink": "Skicka länk till leverantör via VIQMA",
    "adminFieldDisable": "Endast användare som är admin i modulen kan ändra fältet",
    "onlyAdminSave": "Endast användare som är admin i modulen kan spara",
    "search": "Skriv in sökord...",
    "dateDone": "Utförd datum",
    "viqmaPower": "Powered by VIQMA System AB",
    "supplierId": "Leverantör",
    "customerSupplier": "Kund",
    "reportDate": "Rapporterad den",
    "reviewBeforeDate": "Utvärdera senast den",
    "supplierShouldReply": "Lev. besvarar",
    "replied": "Besvarat",
    "departmentId": "Avdelning/Grupp",
    "locationId": "Verksamhetsort",
    "moduleSettings": "Modulinställningar",

    "improvementActivityListStatus.0": "Aktiv",
    "improvementActivityListStatus.1": "Avvaktar beslut",
    "improvementActivityListStatus.2": "Beslut fattat",
    "improvementActivityListStatus.3": "Utvärdera",
    "improvementActivityListStatus.4": "Stängd",
    "improvementActivityListStatus.5": "Ny",
    "improvementActivityListStatus.6": "Avvakta",

    "improvementIdeaListStatus.0": "Aktiverad",
    "improvementIdeaListStatus.4": "Stängd",
    "improvementIdeaListStatus.5": "Ny",
    "improvementIdeaListStatus.6": "Avvakta",

    "improvementIdeaDropdownStatus.0": "Aktivera idé till aktivitet",
    "improvementIdeaDropdownStatus.4": "Stäng idé",
    "improvementIdeaDropdownStatus.5": "Ny",
    "improvementIdeaDropdownStatus.6": "Avvakta",

    "improvementDecisionStatus.0": "Ansvarig användare/grupp har befogenhet att ta beslut",
    "improvementDecisionStatus.1": "Aktivitet skickas för beslut till annan användare med befogenhet",
    "improvementDecisionStatus.2": "Beslut är fattat av annan användare med befogenhet",

    "moduleStatus.1": "Aktiv",
    "moduleStatus.2": "Utvärdera",
    "moduleStatus.4": "Stängd",

    "issueStatus.1": "Aktiv",
    "issueStatus.2": "Utvärdera",
    "issueStatus.3": "Stängd",

    "projectActivityStatus.1": "Aktiv",
    "projectActivityStatus.4": "Stängd",

    "projectActivityChecklistStatus.1": "Nej",
    "projectActivityChecklistStatus.4": "Ja",
    "projectActivityChecklistStatus.9": "Inaktiv",

    "deviationMethod": "Avvikelsemetod",
    "deviationMethod.0": "PDCA",
    "deviationMethod.1": "8D",

    "teamMembers": "Team-medlemmar",
    "sendEmailToTeamMembers": "Skicka e-post till team-medlemmar",
    "desiredEffect": "Önskad effekt",
    "desiredEffectInfo": "Har genomförda åtgärder gett önskad effekt?",

    "desiredEffectComment": "Kommentar",
    "sendEightDReportToTeamMembers": "Skicka e-post med slutförd 8D-rapport till teamet",
    "month.1": "1",
    "month.2": "2",
    "month.3": "3",
    "month.4": "4",
    "month.5": "5",
    "month.6": "6",
    "month.7": "7",
    "month.8": "8",
    "month.9": "9",
    "month.10": "10",
    "month.11": "11",
    "month.12": "12",
    "selectAll": "Markera alla",
    "pieChart": "Cirkeldiagram",
    "barChart": "Stapeldiagram",
    "desiredEffectPopUp": "Kan bara godkännas av ansvarig för avvikelsen.",
    "8dReportPending": "Skickar 8D-rapporter",
    "8dReportSuccess": "8D-rapporter skickades framgångsrikt!",
    "8dReportError": "Misslyckades att skicka vissa 8D-rapporter.",
    "service": "Produkt/Tjänst",
    "quality": "Kvalitet",
    "environment": "Miljö",
    "workEnvironment": "Arbetsmiljö",
    "csr": "CSR",
    "loadBearingStructureWeldingCertificate": "1090/3834",
    "interval": "Intervall",
    "assessedDate": "Bedömd den",
    "qualityCertificationExpires": "Kvalitetscertifiering upphör",
    "environmentCertificationExpires": "Miljöcertifiering upphör",
    "workEnvironmentCertificationExpires": "Arbetsmiljöcertifieringen upphör",
    "csrCertificationExpires": "CSR certifiering upphör",
    "otherCertificationExpires": "Övriga certifieringar upphör",
    "assessmentSentDate": "Skickad den",
    "assessmentAnsweredDate": "Besvarad den",
    "actionPlan": "Handlingsplan",
    "organizationNumber": "Organisationsnummer",
    "contact": "Kontakt",
    "zipCode": "Postnummer",
    "address": "Adress",
    "telephoneNumber": "Telefonnummer",
    "Country": "Land",
    "webPage": "Hemsida",
    "swe": "Svenska",
    "eng": "Engelska",
    "contactInformation": "Kontaktuppgifter",
    "keyFigure": "Nyckeltal",
    "internalAssessment": "Internbedömning",
    "externalResponse": "Svar på externa frågor",
    "supplierDeviations": "Leverantörsavvikelser",
    "end": "Avsluta",
    "supplierQuestionType.1": "Kvalitet",
    "supplierQuestionType.2": "Miljö",
    "supplierQuestionType.3": "Arbetsmiljö",
    "supplierQuestionType.4": "CSR",
    "supplierQuestionType.5": "Övrigt",
    "supplierQuestionType.6": "1090/3834",
    "supplierQuestionType.7": "okänd",
    "subtitle": "Undertext",
    "selfSubtitle": "Egen undertext",
    "points": "Poäng",
    "lastChanged": "Senast ändrad",
    "latestInternalAssessment": "Senast internbedömd den",
    "remedyEffecieny": "Åtgärdseffektivitet",
    "handledInTime": "I tid",
    "handledToLate": "Försent",
    "deviationsReportedInTime": "Avvikelser rapporterade i tid",
    "qualityDeviationCost": "Kvalitetsbristkostnad",
    "accidentEmploymentType": "Anställningsform/Olycka",
    "accidentAbsence": "Sjukfrånvaro/Olycka",
    "absence": "Frånvaro",
    "accidentCause": "Orsak/Olycka",
    "accidentConditions": "Förhållanden/Olycka",
    "cause": "Orsak",
    "statisticType": "Statistiktyp",
    "table": "Tabell",
    "count": "Antal",
    "laborCostInfo": "För att beräkna arbetskostnad utifrån timkostnad",
    "deviationTypeYear": "Avvikelser/Typ År",
    "deviationTypeMonth": "Avvikelser/Typ Månad",
    "accidentTypeYearBar": "Olycka/tillbud/riskobservation år stapeldiagram",
    "accidentTypeYearPie": "Olycka/tillbud/riskobservation år cirkeldiagram",
    "deviationStatisticStartpage": "Avvikelse statistik på startsidan",
    "accidentStatisticStartpage": "Olycka/tillbud/riskobservation statistik på startsidan",
    "customizeStats": "Anpassa statistik",
    "customizeStatsInfo": "Använd rullisterna för att anpassa statistiken som visas. Det går att visa flera statistiktyper samtidigt på sidan och valen som görs anpassar statistiken.",
    "remedyEffecienyStatsInfo": "Statistiken hämtas från avvikelser med status stängd eller utvärdera samt från fälten ”Rapporterat den” och ”Avslutat den” i avvikelseformuläret. Statistiken i tabellen visar medelvärdet för antal dagar till åtgärd och antalen avvikelser.",
    "deviationsReportedInTimeStatsInfo": "Statistiken hämtas från avvikelser med status stängd eller utvärdera samt från fälten ”Utförd senast” (om det är ifyllt) och ”Avslutat den” i avvikelseformuläret.  Statistiken delas upp i hur många som är rapporterade i tid och försent och cirkeldiagrammet visar i procent.",
    "qualityDeviationCostStatsInfo": "Statistiken hämtas oavsett status på registrerade avvikelser samt från fälten ”Kostnad (Kr)” och ”Tidsåtgång (H)” i avvikelseformuläret. För att få ut ”Arbetskostnad” måste timkostnad anges i ”Modulinställningar” på fliken ”Statistik”.",
    "accidentEmploymentTypeStatsInfo": "Statistiken hämtas oavsett status på registrerade olyckor samt från frågan om ”Anställningsform” i olycksformuläret. Även olyckor där frågan inte är angiven syns i statistiken.",
    "accidentAbsenceStatsInfo": "Statistiken hämtas oavsett status på registrerade olyckor samt från frågan om ”Trolig sjukfrånvaro” i olycksformuläret. Även olyckor där frågan inte är angiven syns i statistiken.",
    "accidentCauseStatsInfo": "Statistiken hämtas oavsett status på registrerade olyckor samt från frågan om ”Orsak till olycka” i olycksformuläret. Även olyckor där frågan inte är angiven syns i statistiken.",
    "notSpecified": "Ej angivet",
    "certificate": "Certifikat",
    "internalFiles": "Interna filer",
    "externalFiles": "Externa filer",
    "internalFilesMessageBody": "Internt uppladdade filer kan inte ses av leverantören på leverantörvärderingssidan.",
    "externalFilesMessageBody": "Externt uppladade filer kan ses av leverantören på leverantörvärderingssidan.",
    "expireCertDate": "Ange giltighetstid för certifikat/verifikat",
    "affects": "Påverkar",
    "internalAssessmentInfo": "Gör internbedömning genom att fylla i alla fält i internbedömningen med betyg för varje egenskap. Alt. för uppföljande internbedömning är att man ser över de tidigare betygen och uppdaterar där det behövs. När internbedömningen är klar, sätt dagens datum i fältet ”Senast internbedömd den”. Klart!”",
    "keyFigureInfo": "Nyckeltal kan används för att följa t.ex. leveranssäkerhet och inköpsvolym. Nyckeltal är inte synliga för leverantören på leverantörvärderingssidan.",
    "supplierDeviationsInfo": "Leverantörsavvikelser visar information om vilka avvikelser som är kopplade mot leverantören i avvikelsemodulen.",
    "externalAssessmentInfo": "Externa frågor visar en översikt på svaren leverantören lämnat de senaste åren och ett linjediagram med medelvärdet för respektive typ av frågor.",
    "supplierActionPlanInfo": "Handlingsplaner kan användas för att t.ex. planera att kontakta leverantören, om det behövs en kreditupplysning eller om ni ska göra ett besök hos leverantören. Handlingsplanen kommer att synas på startsidan för användaren som är ansvarig för den.",
    "averageScore": "Medelpoäng",
    "averageValue": "Medelpoäng:  {{value}}",
    "linkSupplierQuestions": "Länk till frågeformulär",
    "supplierKeyFigureInfo": "Nyckeltal kan används för att följa t.ex. leveranssäkerhet och inköpsvolym. Nyckeltal är inte synliga för leverantören på leverantörvärderingssidan.",
    "supplierAffectsInfo": "supplierAffectsInfo",
    "supplierIntervalInfo": "Intervall är valfri funktion som används för att ge adminanvändare i modulen notis på startsidan när det är dags att genomföra bedömning igen samt rödmarkerat datum i listvyn. t.ex.välj 3 år om leverantören har giltigt certifikat som gäller 3 år till, annars 1 år.",
    "internalQuestions": "Interna frågor",
    "externalQuestions": "Externa frågor",
    "supplierAffectsError": "Minst en av fälten 'Kvalitet', 'Miljö', 'Arbetsmiljö', 'CSR' eller '1090/3834' måste vara vald.",


}