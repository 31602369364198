import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import SupplierForm from '../../../forms/SupplierForm'

interface ISupplierFormPageProps {
    refresh: () => void
    isNew: boolean
}

const SupplierFormPage = ({ refresh, isNew }: ISupplierFormPageProps) => {
    const { t } = useTranslation()
    const { supplierId } = useParams<{ supplierId: string }>()
    const {
        isLoading,
        data: selectedSupplier,
    } = useGetQueryByParams(!!supplierId, API.suppliers.getById, "selectedSupplier", supplierId)

    if (isLoading) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('supplier').toLowerCase() }) : t('editValue', { value: t('supplier').toLowerCase() })}</Header>
            <SupplierForm refresh={refresh} isNew={isNew} selectedSupplier={selectedSupplier} />
        </Container>
    )
}

export default SupplierFormPage
