import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupplierResponse } from '../../../api/suppliers';
import { Container, Tab, TabPane } from 'semantic-ui-react';
import SupplierContactInformationForm from './SupplierContactInformationForm';
import SupplierDeviationList from './SupplierDeviationList';
import KeyFigureList from './KeyFigure/KeyFigureList';
import ActionPlanList from './ActionPlan/ActionPlanList';
import InternalAssessmentList from './InternalAssessment/InternalAssessmentList';
import ExternalAssessmentList from './ExternalAssessmentList';
import SupplierFiles from './SupplierFiles';
import { hasModule, MODULES } from '../../../auth/modules';
import { getState } from '../../../state';
import { useIsInRole } from '../../../hooks/role';
import { AdminRoles } from '../../../auth/roles';

interface ISupplierFormProps {
    isNew: boolean
    refresh: () => void
    selectedSupplier?: ISupplierResponse
}

const SupplierForm = ({ refresh, isNew, selectedSupplier }: ISupplierFormProps) => {
    const { t } = useTranslation()
    const { state } = getState()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.SupplierAdminRole)
    const showTab = !isNew && selectedSupplier && isAdmin
    const panes = useMemo(() => [
        { menuItem: t("contactInformation"), render: () => <TabPane><SupplierContactInformationForm isNew={isNew} refresh={refresh} selectedSupplier={selectedSupplier} /></TabPane> },
        showTab ? { menuItem: t("internalFiles"), render: () => <TabPane><SupplierFiles fileCollection="supplier-internal" selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("externalFiles"), render: () => <TabPane><SupplierFiles fileCollection='supplier-external' selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("keyFigure"), render: () => <TabPane><KeyFigureList selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab && hasModule(state)(MODULES.ISSUES) ? { menuItem: t("supplierDeviations"), render: () => <TabPane><SupplierDeviationList selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("externalResponse"), render: () => <TabPane><ExternalAssessmentList selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("internalAssessment"), render: () => <TabPane><InternalAssessmentList selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("actionPlan"), render: () => <TabPane><ActionPlanList selectedSupplier={selectedSupplier} /></TabPane> } : {},
    ], [t, refresh, selectedSupplier, isNew])

    return <Container><Tab panes={panes} /></Container>
};

export default SupplierForm;
