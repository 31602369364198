import moment from 'moment'

export const isInPageRenderMode = () => window.env.REACT_APP_RENDER_MODE === 'page'

export const formatDate = (timestring?: string | Date, format?: string) =>
    timestring ? moment(timestring).format(format ?? 'Y-MM-DD') : ''

export const isEmptyGuid = (guid: string) => guid.trim() === '00000000-0000-0000-0000-000000000000'

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isInThePast = (timestring?: string | Date) =>
    (timestring && moment(timestring) < moment()) as boolean
