import React, { FunctionComponent } from 'react'
import { Plus, Upload, XCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Header,
    Progress,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import styled from 'styled-components'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import FileDropTarget from './fileDropTarget'
import FileRow from './fileRow'
import { FileListing, PendingFile } from './types'

const HiddenFileInput = styled.input`
    width: 0.1px !important;
    height: 0.1px !important;
    opacity: 0;
    overflow: hidden !important;
    position: absolute !important;
    z-index: -1 !important;
`

export interface FilesDumbProps {
    module: string
    displayTitle?: boolean
    files: FileListing[]
    pendingFiles: PendingFile[]
    handleFilesPicked(fileList: File[]): void
    loading: boolean
    readOnly: boolean
    enableDragDrop: boolean
    isInMobileMode?: boolean
    includeToken?: boolean
    canDeleteFiles: () => boolean
    onFileDeleted: (file: FileListing) => void
    onFileUnstaged: (pendingFile: PendingFile) => void
}

export const FilesDumb: FunctionComponent<FilesDumbProps> = ({
    module,
    displayTitle,
    files,
    pendingFiles,
    handleFilesPicked,
    loading,
    isInMobileMode,
    readOnly,
    enableDragDrop,
    includeToken,
    canDeleteFiles,
    onFileDeleted,
    onFileUnstaged,
}) => {
    const { t } = useTranslation()
    const extraFields = Object.keys(files?.find(x => x.extraFields)?.extraFields ?? {})
    return (
        <>
            {displayTitle && <Header>{t('files')}</Header>}

            <Table style={{ marginBottom: isInMobileMode ? 20 : 0 }} basic="very" unstackable>
                <TableHeader>
                    {!readOnly && (
                        <TableRow>
                            <TableHeaderCell />
                            <TableHeaderCell colSpan="4" textAlign="right">
                                <Button
                                    icon
                                    labelPosition="left"
                                    as="label"
                                    htmlFor="file"
                                    primary
                                    type="button"
                                >
                                    <SemanticFeatherIcon
                                        FeatherIcon={Plus}
                                        size="60%"
                                        centerIcon={true}
                                    />{' '}
                                    {t('new')}
                                </Button>
                            </TableHeaderCell>
                        </TableRow>
                    )}

                    <TableRow>
                        <TableHeaderCell>{t('fileType')}</TableHeaderCell>
                        <TableHeaderCell>{t('filename')}</TableHeaderCell>
                        <TableHeaderCell>{t('lastUpdated')}</TableHeaderCell>

                        {extraFields?.map(key => (
                            <Table.HeaderCell key={key}>{t(key)}</Table.HeaderCell>
                        ))}

                        <TableHeaderCell textAlign="right" className="hide-in-print">
                            {!readOnly && (canDeleteFiles?.() ?? false) && <>{t('deleteFile')}</>}
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {files?.map(file => (
                        <FileRow
                            key={file.name}
                            module={module}
                            t={t}
                            file={file}
                            canDeleteFiles={canDeleteFiles}
                            onFileDeleted={() => onFileDeleted(file)}
                            readOnly={readOnly}
                            includeToken={includeToken ?? false}
                        />
                    ))}
                    {pendingFiles?.map(f => (
                        <TableRow key={f.file.name}>
                            {f.status === 'pending' ? (
                                <>
                                    <TableCell collapsing>
                                        <SemanticFeatherIcon FeatherIcon={Upload} />
                                    </TableCell>
                                    <TableCell>{f.file.name}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Button
                                            floated="right"
                                            size="small"
                                            icon={<SemanticFeatherIcon FeatherIcon={XCircle} />}
                                            type="button"
                                            onClick={e => {
                                                e.preventDefault()
                                                onFileUnstaged(f)
                                            }}
                                        />
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell colSpan="4">
                                    <Progress
                                        label={f.file.name}
                                        total={f.file.size}
                                        value={f.bytesUploaded}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <HiddenFileInput
                type="file"
                id="file"
                multiple
                onChange={e => {
                    handleFilesPicked(e.currentTarget.files as any)
                    e.target.value = ''
                }}
            />

            {!readOnly && enableDragDrop && <FileDropTarget onFileDropped={handleFilesPicked} />}
        </>
    )
}
