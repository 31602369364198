import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter, HashRouter } from 'react-router-dom'

// Use CDN version for now, since a bug in the css makes webpack go crazy
// import 'semantic-ui-css/semantic.min.css'

import 'react-data-grid/dist/react-data-grid.css'
import 'react-datepicker/dist/react-datepicker.css'
import './i18n'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { handleCallback, registerLogoutHandler } from './auth'

import { isInPageRenderMode } from './actions/util'
import AdminModule from './components/admin'
import Error from './components/common/error'
import AppLoader from './components/global/appLoader'
import MessageHost from './components/global/messageHost'
import Routing from './components/global/routing'
import GoalsModule from './components/goals'
import ProjectsModule from './components/modules/projects'
import ImprovementsModule from './components/modules/improvements';
import MySkills from './components/skills/mySkils'
import StartModule from './components/start'
import ProcessMap from './components/start/process'
import SystematicTasksModule from './components/systematicTasks'
import { MobileModeProvider } from './hooks/mobileMode'
import { mainReducer } from './reducer'
import { StateProvider } from './state'
import { getInitialState } from './state/initial'
import GlobalStyles from './styles/globalStyles'
import SWRConfigurationProvider from './SWRConfigurationProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import DocsModule from './components/modules/docs'
import IssuesModule from './components/modules/issues'
import SuppliersModule from './components/modules/suppliers'

if (!window.env) window.env = process.env

const displayStartupError = (error: string, topic?: string) => {
    const root = document.getElementById('root')

    if (root) ReactDOM.render(<Error message={error} standAlone={true} topic={topic} />, root)
    else console.log(topic, error)
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 600 * 1000,
        },
    },
})

const Setup: React.FunctionComponent<{ isInPageRenderMode: boolean }> = ({
    children,
    isInPageRenderMode,
}) => (
    <StateProvider initialState={getInitialState()} reducer={mainReducer}>
        <DndProvider backend={HTML5Backend}>
            <MobileModeProvider>
                <QueryClientProvider client={queryClient}>
                    <SWRConfigurationProvider>
                        <GlobalStyles />
                        <MessageHost />
                        {isInPageRenderMode ? (
                            <BrowserRouter>{children}</BrowserRouter>
                        ) : (
                            <HashRouter>{children}</HashRouter>
                        )}
                        <ToastContainer />
                    </SWRConfigurationProvider>
                </QueryClientProvider>
            </MobileModeProvider>
        </DndProvider>
    </StateProvider>
)

handleCallback()
    .then(() => {
        if (isInPageRenderMode()) {
            ReactDOM.render(
                <Setup isInPageRenderMode={true}>
                    <Routing />
                </Setup>,
                document.getElementById('root'),
            )
        } else {
            registerLogoutHandler()
            //Render individual modules
            const tryRenderModule = (elementId: string, module: () => JSX.Element) => {
                const element = document.getElementById(elementId)

                if (element) {
                    ReactDOM.render(
                        <Setup isInPageRenderMode={false}>
                            <AppLoader>{module()}</AppLoader>
                        </Setup>,
                        element,
                    )
                }
            }

            tryRenderModule('projects-module', () => <ProjectsModule />)
            tryRenderModule('improvements-module', () => <ImprovementsModule />)
            tryRenderModule('startpage', () => <StartModule />)
            tryRenderModule('process-map', () => <ProcessMap />)
            tryRenderModule('document-module', () => <DocsModule />)
            tryRenderModule('myskills-page', () => <MySkills />)
            tryRenderModule('goals-module', () => <GoalsModule />)
            tryRenderModule('admin-module', () => <AdminModule />)
            tryRenderModule('systematicTasks-module', () => <SystematicTasksModule />)
            tryRenderModule('issues-module', () => <IssuesModule />)
            tryRenderModule('suppliers-module', () => <SuppliersModule />)

        }
    })
    .catch(displayStartupError)
