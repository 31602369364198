import React from 'react'
import { Routes, Route } from 'react-router'
import MySkills from './mySkils'

const SkillsModule: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={'/'} element={<h1>SkillsModule</h1>} />
            <Route path="me" element={<MySkills />} />
        </Routes>
    )
}

export default SkillsModule
