import { get, post, put, destroy } from "../restClient";

export interface ISupplierDto {
    id: number;
    name: string;
    group: string;
    active: boolean | null;
    language: string;
    service: string;
    quality: boolean | null;
    environment: boolean | null;
    workEnvironment: boolean | null;
    csr: boolean | null;
    loadBearingStructureWeldingCertificate: boolean | null;
    qualityCertificationExpires: Date | null;
    environmentCertificationExpires: Date | null;
    workEnvironmentCertificationExpires: Date | null;
    csrCertificationExpires: Date | null;
    otherCertificationExpires: Date | null;
    email: string;
    interval: number | null;
    assessedDate: Date | null;
    assessmentSentDate: Date | null;
    assessmentAnsweredDate: Date | null;
    actionPlan: boolean;
    contact: string;
    position: string;
    zipCode: string;
    organizationNumber: string;
    location: string;
    telephoneNumber: string;
    country: string;
    webpage: string;
    description: string;
    comment: string;
    address: string;
    averageScore: number | null
}

export interface ISupplierResponse {
    id: number;
    name: string;
    group: string;
    active: boolean | null;
    language: string;
    service: string;
    quality: boolean | null;
    environment: boolean | null;
    workEnvironment: boolean | null;
    csr: boolean | null;
    loadBearingStructureWeldingCertificate: boolean | null;
    qualityCertificationExpires: Date | null;
    environmentCertificationExpires: Date | null;
    workEnvironmentCertificationExpires: Date | null;
    csrCertificationExpires: Date | null;
    otherCertificationExpires: Date | null;
    email: string;
    interval: number | null;
    contact: string;
    position: string;
    zipCode: string;
    organizationNumber: string;
    location: string;
    telephoneNumber: string;
    country: string;
    webpage: string;
    description: string;
    comment: string;
    address: string;
    latestInternalAssessment: Date | null;
    sId: string | null;
}

export interface IKeyFigureDto {
    id: number;
    companyId: number | null;
    supplierId: number | null;
    keyFigure: string;
    value: number | null;
    unit: string;
    year: number | null;
}
export interface ISupplierDeviationDto {
    id: number;
    ownId: number;
    status: number;
    departmentId: number | null;
    responsibleId: string;
    reportedAt: Date | null;
    topic: string;
    cost: number | null;
    time: number | null;
    doneBefore: Date | null;
}

export interface IActionPlanDto {
    id: number;
    status: number
    companyId: number | null;
    supplierId: number | null;
    responsibleId: string;
    topic: string;
    description: string;
    doneBefore: Date | null;
    useReview: boolean | null;
    reviewDate: Date | null;
    reviewResponsibleId: string;
    reviewText: string;
    endedDate: Date | null;
    endedBy: string;
}
export interface ISupplierAnswerDto {
    id: number;
    companyId: number | null;
    supplierId: number | null;
    questionId: number;
    points: number;
    comment: string;
    date: Date | null;
    type: number;
    swedishQuestion: string;
    englishQuestion: string;
    swedishComment: string;
    englishComment: string;
    swedishExtra: string;
    englishExtra: string;
}
export interface IInternalAssessmentDto {
    id: number;
    companyId: number | null;
    supplierId: number | null;
    questionId: number | null;
    type: number | null;
    swedishQuestion: string;
    englishQuestion: string;
    comment: string;
    points: number | null;
    date: Date | null;
}
export interface ISupplierQuestionDto {
    id: number;
    companyId: number | null;
    type: number | null;
    swedishQuestion: string;
    englishQuestion: string;
    swedishDetailedDescription: string;
    englishDetailedDescription: string;
}
export interface IInternalAssessmentRequest {
    questions: ISupplierQuestionDto[]
}

export const getAll = () => get<ISupplierDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier`)

export const getById = (id: string) => get<ISupplierResponse>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}`)

export const create = (data: ISupplierResponse) => post<ISupplierResponse>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier`, data)

export const edit = (id: number, data: ISupplierResponse) => put<ISupplierResponse>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}`, data)

export const getKeyFigures = (id: string) => get<IKeyFigureDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/key-figure`)

export const createKeyFigure = (id: number, data: IKeyFigureDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/key-figure`, data)

export const editKeyFigure = (id: number, data: IKeyFigureDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/key-figure`, data)

export const destroyKeyFigure = (id: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/key-figure/${id}`)

export const getDeviations = (id: string) => get<ISupplierDeviationDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/deviations`)

export const getActionPlans = (id: string) => get<IActionPlanDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/action-plan`)

export const createActionPlan = (id: number, data: IActionPlanDto) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/action-plan`, data)

export const editActionPlan = (id: number, data: IActionPlanDto) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/action-plan`, data)

export const destroyActionPlan = (id: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/action-plan/${id}`)

export const getExternalAnswers = (id: number) => get<ISupplierAnswerDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/answers`)

export const getAllExternalQuestions = () => get<ISupplierQuestionDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/external-questions`)

export const getExternalQuestions = (id: number) => get<ISupplierQuestionDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/external-questions`)

export const getInternalQuestions = (id: number) => get<ISupplierQuestionDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/internal-questions`)

export const getInternalAssessment = (id: number) => get<IInternalAssessmentDto[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/internal-assessment`)

export const createInternalAssessment = (id: number, data: IInternalAssessmentRequest) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/internal-assessment`, data)

export const destroyInternalAssessment = (supplierId: number, answerId: number) => destroy(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${supplierId}/internal-assessment/${answerId}`)

export const sendLink = (id: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/send/link`)

export const remindActionPlan = (supplierId: number, actionPlanId: number) => post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${supplierId}/action-plan/${actionPlanId}/remind`)

export const editSupplierInterval = (id: number, data: { interval: number }) => put(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/${id}/interval`, data)

