import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Card, CardContent, CardDescription, CardGroup, CardHeader, CardMeta, Form } from 'semantic-ui-react';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import * as API from '../../../../../api'
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IInternalAssessmentRequest, ISupplierQuestionDto } from '../../../../../api/suppliers';
import FormActions from '../../../FormActions';
import ControlTextArea from '../../../../fields/ControlTextArea';
import ControlRadioGroup from '../../../../fields/ControlRadioGroup';
import { useLanguage } from '../../../../../hooks/language';

interface ICreateInternalAssessmentFormProps {
    onCancel: () => void
    refresh: () => void
    supplierId: number
    questions: ISupplierQuestionDto[]
}

export const createInternalAssessmentSchema = z.object({
    questions: z.array(z.object({
        id: z.coerce.number().min(1, { message: "required" }),
        points: z.number().optional().nullable(),
        comment: z.string().optional().nullable(),
    }))
});


const CreateInternalAssessmentForm = ({ refresh, onCancel, supplierId, questions }: ICreateInternalAssessmentFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const language = useLanguage()
    const { control, handleSubmit } = useForm<IInternalAssessmentRequest>({
        resolver: zodResolver(createInternalAssessmentSchema),
        defaultValues: { questions: questions }
    });

    const { fields } = useFieldArray({
        control,
        name: "questions",
    })

    const onInternalAssessmentRequest = async (data: IInternalAssessmentRequest) => await API.suppliers.createInternalAssessment(supplierId, data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onInternalAssessmentRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
            onCancel()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <FormActions
                onCancel={() => onCancel()}
                disabled={isSaving}
            />
            <CardGroup itemsPerRow={2}>
                {fields.map((field, index) => (
                    <Card key={field.id}>
                        <CardContent>
                            <CardHeader>{language === "sv" ? field.swedishQuestion : field.englishQuestion}</CardHeader>
                            <CardMeta>{language === "sv" ? field.swedishDetailedDescription : field.englishDetailedDescription}</CardMeta>
                            <CardDescription>
                                <ControlRadioGroup direction='row-reverse' control={control} name={`questions.${index}.points`} disabled={isSaving} label={t('points')}
                                    options={[
                                        { value: 0, text: "0p" },
                                        { value: 1, text: "1p" },
                                        { value: 2, text: "2p" },
                                        { value: 3, text: "3p" },
                                        { value: 4, text: "4p" },
                                        { value: 5, text: "5p" },
                                        { value: 6, text: "6p" },
                                        { value: 7, text: "7p" },
                                        { value: 8, text: "8p" },
                                        { value: 9, text: "9p" },
                                        { value: 10, text: "10p" },
                                    ]} />
                            </CardDescription>
                        </CardContent>

                        <CardContent extra>
                            <ControlTextArea
                                name={`questions.${index}.comment`}
                                label={t('comment')}
                                control={control}
                                disabled={isSaving} />
                        </CardContent>

                    </Card>
                ))}
            </CardGroup>
            <FormActions
                onCancel={() => onCancel()}
                disabled={isSaving}
            />
        </Form>
    );
};

export default CreateInternalAssessmentForm;
