import React, { useMemo, useState } from 'react'
import { Button, Checkbox, Message } from 'semantic-ui-react'
import Loading from '../../../../common/Loading'
import { useTranslation } from 'react-i18next';
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import * as API from '../../../../../api'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon';
import { Edit, Trash2 } from 'react-feather';

import TableReact from '../../../../wrappers/TableReact';
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import ModalWrapper from '../../../../wrappers/ModalWrapper';
import BatchExtraFieldForm from '../../../../forms/BatchExtraFieldForm';
import { dictionaryToArray } from '../../../../../utils/Mappings/Objects';
import { useDeleteExtraField } from './useDeleteExtraField';
import ExtraFieldForm from '../../../../forms/ExtraFieldForm';
import { ModuleCollection } from '../../../../../api/issues';
export interface IExtrafieldItem {
    key: string
    type: number
    required: boolean
}
interface IExtraFieldListProps {
    moduleCollection: ModuleCollection
    messageHeader: string
    messageBody: string
}

const ExtraFieldList = ({ moduleCollection, messageHeader, messageBody }: IExtraFieldListProps) => {
    const { t } = useTranslation()
    const [showAddModal, setShowAddModal] = useState(false);

    const {
        isLoading: isLoadingExtra,
        data: extra,
        refresh: refreshExtra
    } = useGetQueryByParams(true, API.issues.getExtraFields, "newExtra", moduleCollection)
    const { isDestroying, onDestroy } = useDeleteExtraField(refreshExtra)
    const [selectedField, setSeletedField] = useState<IExtrafieldItem | null>(null)
    const handleSetSelectedField = (field: IExtrafieldItem): void => {
        setSeletedField(field)
    }

    const closeSelectedField = (): void => {
        setSeletedField(null)
    }

    const data = extra ? dictionaryToArray(extra.extraFields) : [];

    const columnHelper = createColumnHelper<any>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.accessor('key', {
                id: 'fieldName',
                header: t('fieldName') as any,
            }),
            columnHelper.accessor('type', {
                id: 'type',
                header: t('fieldType') as any,
                cell: (info) => <span>{t(`fieldType.${info.getValue()}`)}</span>
            }),
            columnHelper.accessor("required", {
                id: "required",
                header: t('required') as any,
                enableColumnFilter: false,
                meta: { checkbox: true },
                cell: ({ row }) => <Checkbox checked={!!row.original.required} />
            }),
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 65,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' disabled={isDestroying} onClick={() => handleSetSelectedField(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                            confirmText: t('confirm.deleteValue', { value: row.original.key }),
                            id: extra && extra.id,
                            key: row.original.key
                        })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />

                    </>
            }),
        ], [columnHelper, t, extra, onDestroy, isDestroying])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    if (isLoadingExtra) {
        return <Loading />
    }
    return (
        <>
            <Message info>
                <Message.Header>
                    {messageHeader}
                </Message.Header>
                {messageBody}
            </Message>

            <TableReact table={table}
                onAddCallback={() => setShowAddModal(true)}
                canExport={false}
                overflowable />

            <ModalWrapper title={t('addValue', { value: t('extraFields').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} size='small'>
                <BatchExtraFieldForm moduleCollection={moduleCollection} extraFieldTemplate={extra} refresh={refreshExtra} onCancel={() => setShowAddModal(false)} />
            </ModalWrapper>

            {selectedField && extra && <ModalWrapper title={t('editValue', { value: t('extraFields').toLowerCase() })} open={!!selectedField} onClose={closeSelectedField} size='small'>
                <ExtraFieldForm extraFieldTemplate={extra} selectedField={selectedField} refresh={refreshExtra} onCancel={closeSelectedField} />
            </ModalWrapper>}
        </>

    );
}

export default ExtraFieldList


