import React, { useMemo, useState } from 'react'
import * as API from '../../../../../api'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import Loading from '../../../../common/Loading'
import { Grid, GridRow, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { getGraphColorsFactory } from '../../../../../styles'
import { buildIssueGroupTitleKey, buildIssueTitleKey, transformToStatisticsYearMonthRow } from '../../../../../utils/Mappings/Issues'
import { getState } from '../../../../../state'
import { IDropdownOption } from '../../../../fields/ControlDropdown'
import { IDeviationStatisticsResponse } from '../../../../../api/issues'
import DeviationStatisticFilters from './DeviationStatisticFilters'
import EffeciencyStatistic from './EffeciencyStatistic'
import QualityCostStatistic from './QualityCostStatistic'
import { createColumnHelper, useReactTable, getExpandedRowModel, getGroupedRowModel, getCoreRowModel, getFilteredRowModel, getSortedRowModel, getPaginationRowModel, getFacetedRowModel, getFacetedUniqueValues } from '@tanstack/react-table'
import TableReact, { FooterLabel } from '../../../../wrappers/TableReact'
import { LocationDisplay } from '../../../../common/globalStateDisplay'
import { MultiDropdownFilter, multiFilter } from '../../../../wrappers/TableReact/Filters/MultiDropdownFilter'
import { getLocationFromState } from '../../../../../state/helpers'
import CustomBarLabel from '../CustomBarLabel'

const DeviationStatistics = () => {
    const {
        state,
    } = getState()
    const { t } = useTranslation()
    const [selectedYears, setSelectedYears] = useState<number[]>([new Date().getFullYear()]);
    const [selectedMonths, setSelectedMonths] = useState<number[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
    const [selectedCauseCodes, setSelectedCauseCodes] = useState<number[]>([]);
    const [selectedErrorCodes, setSelectedErrorCodes] = useState<number[]>([]);
    const [selectedStats, setSelectedStats] = useState<number[]>([2]);
    const hasMonth = selectedMonths.length
    const hasReason = selectedTypes.length
    const hasDepartment = selectedDepartments.length
    const hasLocations = state.locations?.length && selectedLocations.length



    const barColors = getGraphColorsFactory()
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.issues.postIssuesPerSelectedYear, "issuesPerYear", { selectedYears, selectedMonths, selectedDepartments, selectedLocations, selectedTypes, selectedErrorCodes, selectedCauseCodes })

    const {
        isLoading: isLoadingTypes,
        data: types,
    } = useGetQueryByParams(true, API.issues.getDeviationTypesHierarchy, "deviationTypesHierarchy")

    const columnHelper = createColumnHelper<IDeviationStatisticsResponse>()

    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            ...(
                hasReason
                    ? [columnHelper.accessor('reason', {
                        id: 'reason',
                        header: t('issueType') as any,
                    }),
                    ]
                    : []
            ),
            ...(
                hasDepartment
                    ? [columnHelper.accessor('department', {
                        id: 'department',
                        header: t('departmentId') as any,
                    }),
                    ]
                    : []
            ),
            ...(
                hasLocations
                    ? [columnHelper.accessor((r) => r.locationId ? getLocationFromState(state)(r.locationId)?.name : "", {
                        id: "locationId",
                        header: `${t('locationId')}`,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={state.locations?.map(d => ({ value: d.id, text: d.name })) ?? []}
                                />
                            ),
                        },
                        cell: ({ row }) => <LocationDisplay locationId={row.original.locationId} />
                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('count', {
                id: 'count',
                header: t('count') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.count)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, hasLocations, state, hasReason, hasDepartment, hasMonth])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        enableGlobalFilter: false,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data || isLoadingTypes || !types) {
        return <Loading />
    }

    const mappedData = {
        barData: transformToStatisticsYearMonthRow(
            data ? data : [],
            x => buildIssueTitleKey(x, selectedYears, selectedMonths, t),
            x => ({ [buildIssueGroupTitleKey(x, types, selectedYears, selectedMonths, selectedDepartments, selectedLocations, selectedTypes, selectedErrorCodes, selectedCauseCodes, state, t)]: x.count }),
        ),
    };

    const handleDropdownChange = (
        value: number[],
        selectedState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        options: IDropdownOption[]
    ) => {
        if (value.includes(0)) {
            if (selectedState.length === options.length - 1) {
                setState([]);
            } else {
                setState(options.slice(1).map(option => option.value as number));
            }
        } else {
            setState(value);
        }
    };

    return (
        <Grid stackable>
            <GridRow>
                <Grid.Column>
                    <Message info>
                        <Message.Header>
                            {t('customizeStats')}
                        </Message.Header>
                        {t('customizeStatsInfo')}
                    </Message>
                </Grid.Column>
            </GridRow>
            <DeviationStatisticFilters selectedYears={selectedYears}
                types={types}
                selectedMonths={selectedMonths}
                selectedDepartments={selectedDepartments}
                selectedLocations={selectedLocations}
                selectedTypes={selectedTypes}
                selectedStats={selectedStats}
                selectedErrorCodes={selectedErrorCodes}
                selectedCauseCodes={selectedCauseCodes}
                handleDropdownChange={handleDropdownChange}
                setSelectedYears={setSelectedYears}
                setSelectedMonths={setSelectedMonths}
                setSelectedDepartments={setSelectedDepartments}
                setSelectedLocations={setSelectedLocations}
                setSelectedTypes={setSelectedTypes}
                setSelectedErrorCodes={setSelectedErrorCodes}
                setSelectedCauseCodes={setSelectedCauseCodes}
                setSelectedStats={setSelectedStats} />

            {selectedStats.includes(1) && <GridRow columns={1}>
                <Grid.Column>
                    <Header as='h3' textAlign='center'>{t('table')}</Header>
                </Grid.Column>
                <Grid.Column >
                    <TableReact table={table}
                        canExport
                        overflowable />
                </Grid.Column></GridRow>}

            {selectedStats.includes(2) && (mappedData?.barData?.rows?.length ?? 0) > 0 ? (
                <GridRow columns={1}>
                    <Grid.Column>
                        <Header as='h3' textAlign='center'>{t('barChart')}</Header>
                    </Grid.Column>
                    <Grid.Column>

                        <ResponsiveContainer height={500}
                        >
                            <BarChart data={mappedData?.barData?.rows}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <CartesianGrid vertical={false} />
                                <Legend layout="horizontal" />
                                {mappedData?.barData?.dataColumns?.map(x => (
                                    <Bar key={x} isAnimationActive={false} stackId="0" dataKey={x} fill={barColors()}>
                                        <LabelList dataKey={x} content={CustomBarLabel} />
                                    </Bar>
                                ))}
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid.Column>
                </GridRow>
            ) : null}

            <EffeciencyStatistic selectedYears={selectedYears} selectedMonths={selectedMonths} selectedStats={selectedStats} />

            {selectedStats.includes(5) ? <QualityCostStatistic selectedYears={selectedYears} selectedMonths={selectedMonths} selectedStats={selectedStats} /> : null}

        </Grid>
    )
}

export default DeviationStatistics