import React from 'react'
import { formatDate, isInThePast } from '../../actions/util'

export interface DateDisplayProps {
    date?: string | Date
    checkForPassed?: boolean
}

const DateDisplay: React.FunctionComponent<DateDisplayProps> = ({
    date,
    checkForPassed = true,
}) => {
    const style = date && checkForPassed && isInThePast(date) ? { color: 'red' } : {}

    return <span style={style}>{formatDate(date)}</span>
}

export default DateDisplay
