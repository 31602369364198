import React from 'react'
import { TFunction } from 'i18next'


import SystematicTasksModule from '../components/systematicTasks'
import ObjectsModule from '../components/objects'
import RisksModule from '../components/risks'
import SkillsModule from '../components/skills'
import GoalsModule from '../components/goals'
import LawsModule from '../components/laws'
import ChemicalsModule from '../components/chemicals'
import SuppliersModule from '../components/modules/suppliers'
import SurveysModule from '../components/surveys'
import StartModule from '../components/start'
import { ApplicationState } from '../state/initial'
import DocsModule from '../components/modules/docs'
import ProjectsModule from '../components/modules/projects'
import ImprovementsModule from '../components/modules/improvements'
import IssuesModule from '../components/modules/issues'


export const MODULES = {
    START: 1,
    DOCS: 2,
    SKILLS: 6,
    ISSUES: 3,
    PROJECTS: 4,
    SYSTEMATIC_TASKS: 11,
    IMPROVEMENTS: 5,
    OBJECTS: 27,
    RISKS: 14,
    GOALS: 7,
    LAWS: 8,
    CHEMICALS: 22,
    SUPPLIERS: 16,
    SURVEYS: 13,
}

export const FILECOLLECTIONNAME = {
    DOCS: 1,
    PROJECTS: 2,
    GOALS: 3,
    IMPROVEMENTIDEAS: 4,
    IMPROVEMENTACTIVITIES: 5,
    ISSUES: 6,
    ISSUESACCIDENTS: 7
}
export const fileCollectionKeys = {
    [FILECOLLECTIONNAME.DOCS]: 'docs',
    [FILECOLLECTIONNAME.PROJECTS]: 'projects',
    [FILECOLLECTIONNAME.GOALS]: 'goals',
    [FILECOLLECTIONNAME.IMPROVEMENTIDEAS]: 'improvementIdeas',
    [FILECOLLECTIONNAME.IMPROVEMENTACTIVITIES]: 'improvementActivities',
    [FILECOLLECTIONNAME.ISSUES]: 'issues',
    [FILECOLLECTIONNAME.ISSUESACCIDENTS]: 'issuesAccidents',
}

export const moduleKeys = {
    [MODULES.START]: 'start',
    [MODULES.DOCS]: 'docs',
    [MODULES.SKILLS]: 'skills',
    [MODULES.ISSUES]: 'issues',
    [MODULES.PROJECTS]: 'projects',
    [MODULES.SYSTEMATIC_TASKS]: 'systematicTasks',
    [MODULES.IMPROVEMENTS]: 'improvements',
    [MODULES.OBJECTS]: 'objects',
    [MODULES.RISKS]: 'risks',
    [MODULES.GOALS]: 'goals',
    [MODULES.LAWS]: 'laws',
    [MODULES.CHEMICALS]: 'chemicals',
    [MODULES.SUPPLIERS]: 'suppliers',
    [MODULES.SURVEYS]: 'surveys',
}

export interface ModuleInfo {
    key: string
    component: JSX.Element
    text: string
    excludeFromMenu?: boolean
    image: string
    imageDark: string
}

const modules: { [id: number]: (t: TFunction) => ModuleInfo } = {
    [MODULES.START]: (t: TFunction) => ({
        key: '',
        component: <StartModule />,
        text: '',
        excludeFromMenu: true,
        image: '',
        imageDark: '',
    }),
    [MODULES.DOCS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.DOCS],
        component: <DocsModule />,
        text: t('docs'),
        image: 'dokumentRutiner.svg',
        imageDark: 'dokumentRutiner_black.svg',
    }),
    [MODULES.SKILLS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.SKILLS],
        component: <SkillsModule />,
        text: t('skills'),
        image: 'kompetens.svg',
        imageDark: 'kompetens_black.svg',
    }),
    [MODULES.ISSUES]: (t: TFunction) => ({
        key: moduleKeys[MODULES.ISSUES],
        component: <IssuesModule />,
        text: t('issues'),
        image: 'avvikelser.svg',
        imageDark: 'avvikelser_black.svg',
    }),
    [MODULES.PROJECTS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.PROJECTS],
        component: <ProjectsModule />,
        text: t('projects'),
        image: 'projekt.svg',
        imageDark: 'projekt_black.svg',
    }),
    [MODULES.SYSTEMATIC_TASKS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.SYSTEMATIC_TASKS],
        component: <SystematicTasksModule />,
        text: t('systematicTasks'),
        image: 'systematiskauppgifter.svg',
        imageDark: 'systematiskauppgifter_black.svg',
    }),
    [MODULES.IMPROVEMENTS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.IMPROVEMENTS],
        component: <ImprovementsModule />,
        text: t('improvements'),
        image: 'forbattringar.svg',
        imageDark: 'forbattringar_black.svg',
    }),
    [MODULES.OBJECTS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.OBJECTS],
        component: <ObjectsModule />,
        text: t('objects'),
        image: 'objekt.svg',
        imageDark: 'objekt_black.svg',
    }),
    [MODULES.RISKS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.RISKS],
        component: <RisksModule />,
        text: t('risks'),
        image: 'riskMojlighet.svg',
        imageDark: 'riskMojlighet_black.svg',
    }),
    [MODULES.GOALS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.GOALS],
        component: <GoalsModule />,
        text: t('goals'),
        image: 'mal.svg',
        imageDark: 'mal_black.svg',
    }),
    [MODULES.LAWS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.LAWS],
        component: <LawsModule />,
        text: t('laws'),
        image: 'lagar.svg',
        imageDark: 'lagar_black.svg',
    }),
    [MODULES.CHEMICALS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.CHEMICALS],
        component: <ChemicalsModule />,
        text: t('chemicals'),
        image: 'kemikalier.svg',
        imageDark: 'kemikalier_black.svg',
    }),
    [MODULES.SUPPLIERS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.SUPPLIERS],
        component: <SuppliersModule />,
        text: t('suppliers'),
        image: 'leverantorer.svg',
        imageDark: 'leverantorer_black.svg',
    }),
    [MODULES.SURVEYS]: (t: TFunction) => ({
        key: moduleKeys[MODULES.SURVEYS],
        component: <SurveysModule />,
        text: t('surveys'),
        image: 'undersokningar.svg',
        imageDark: 'undersokningar_black.svg',
    }),
}

export const getModule = (moduleId: number, t: TFunction): ModuleInfo | null => {
    if (!modules[moduleId]) {
        // if (isDevelopment())
        //     console.info(`Module ${moduleId} not found`);

        return null
    }

    return modules[moduleId](t)
}

export const hasModule = (state: ApplicationState) => (module: number) =>
    state.modules?.indexOf(module) !== -1
