import { ProfileWithRoles } from '../auth/roles'

export interface User {
    id: string
    oldId: number
    name: string
    virtual: boolean
    active: boolean
}

export interface Department {
    id: number
    name: string
}

export interface Position {
    id: number
    name: string
}

export interface Location {
    id: number
    name: string
}

export interface Company {
    id: number
    name: string
    laborCost: number | null
}

export interface Error {
    topic: string
    message: string
}
export interface ISupplier {
    id: number
    name: string
}

export interface ApplicationState {
    users: User[]
    allUsers: User[]
    departments: Department[]
    companyPositions: Position[]
    concernPositions: Position[]
    locations?: Location[]
    company?: Company
    concern?: Company
    hasSubCompanies: boolean
    errors: Error[]
    modules?: number[]
    userProfile?: ProfileWithRoles
    companyLogoPath?: string
    suppliers?: ISupplier[]
}

export const getInitialState = () => {
    return {
        users: [],
        allUsers: [],
        departments: [],
        companyPositions: [],
        concernPositions: [],
        errors: [],
        modules: [],
        suppliers: [],
        hasSubCompanies: false
    }
}
