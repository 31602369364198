import { TFunction } from 'i18next'
import { ApplicationState, User } from './initial'

export const getUserFromState =
    ({ users, allUsers }: ApplicationState) =>
        (userId: any, includeVirtual = false) =>
            (includeVirtual ? allUsers : users).find(u =>
                typeof userId === 'number' ? u.oldId === userId : u.id === userId,
            )

export const getUserOptionsFromState =
    ({ users }: ApplicationState) =>
        (predicate: (user: User) => boolean = _ => true, spliceInUserId?: string) => {
            const userOptions = users
                .filter(predicate)
                .map(d => ({
                    key: d.id.toString(),
                    text: d.name,
                    value: d.id,
                }))
                .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

            if (spliceInUserId && !userOptions.filter(x => x.key === spliceInUserId).length) {
                const user = users.find(x => x.id.toString() === spliceInUserId)
                if (user) {
                    userOptions.push({
                        key: user?.id.toString(),
                        text: user.name,
                        value: user.id,
                    })
                }
            }

            return userOptions
        }
export const getUserOptionsFromStateOverride =
    ({ users }: ApplicationState) =>
        (t: TFunction, predicate: (user: User) => boolean = _ => true, spliceInUserId?: string) => {
            // Create a map for quick access to the active status of each user by their id
            const activeStatusMap = new Map(users.map(u => [u.id, u.active]));

            const userOptions = users
                .filter(predicate)
                .map(d => ({
                    key: d.id.toString(),
                    text: createUserNameDisplayFromUser(t)(d, d.id),
                    value: d.id,
                }))
                .sort((a, b) => {
                    // Retrieve active status from the map
                    const aActive = activeStatusMap.get(a.value);
                    const bActive = activeStatusMap.get(b.value);

                    // Sort by active status first
                    if (aActive && !bActive) {
                        return -1;
                    } else if (!aActive && bActive) {
                        return 1;
                    }
                    // Then sort alphabetically by text for users with the same active status
                    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
                });

            // Logic for splicing in user based on spliceInUserId
            if (spliceInUserId && !userOptions.find(x => x.key === spliceInUserId)) {
                const user = users.find(x => x.id.toString() === spliceInUserId);
                if (user) {
                    userOptions.push({
                        key: user.id.toString(),
                        text: createUserNameDisplayFromUser(t)(user, user.id),
                        value: user.id,
                    });
                }
            }

            return userOptions;
        }

export const getDepartmentFromState =
    ({ departments }: ApplicationState) =>
        (departmentId: number) =>
            departments.find(d => d.id === departmentId)

export const getLocationFromState =
    ({ locations }: ApplicationState) =>
        (locationId: number) =>
            locations?.find(d => d.id === locationId)

export const getSupplierFromState =
    ({ suppliers }: ApplicationState) =>
        (supplierId: number) =>
            suppliers?.find(d => d.id === supplierId)

export const createUserNameDisplay =
    (state: ApplicationState, t: TFunction) => (userId: string, fallbackValue?: string) => {
        const user = getUserFromState(state)(userId)
        return createUserNameDisplayFromUser(t)(user, fallbackValue ?? '')
    }

export const createUserNameDisplayFromUser =
    (t: TFunction) => (user?: { active: boolean; name: string }, fallbackValue?: string) =>
        `${user?.name ?? fallbackValue ?? ''} ${user?.active === false ? `(${t('inactive')})` : ''}`

export const getPositionFromState =
    ({ companyPositions, concernPositions }: ApplicationState) =>
        (id: number) =>
            companyPositions.find(x => x.id === id) ?? concernPositions.find(x => x.id === id)
