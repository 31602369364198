import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Form, Message, Segment, Table } from 'semantic-ui-react'
import { formatDate } from '../../actions/util'
import { FILECOLLECTIONNAME, fileCollectionKeys } from '../../auth/modules'
import { useCompanyOptions } from '../../hooks/company'
import { getFileCollectionName } from '../../hooks/files'
import { useFilesAdmin } from '../../hooks/files/admin'
import ActionButton from '../common/actionButton'
import ErrorBox from '../common/errorBox'
import { FileIcon, FileLink } from '../common/files'

const AdminFilesPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { options: companyOptions } = useCompanyOptions()

    const moduleOptions = useMemo(
        () =>
            [FILECOLLECTIONNAME.DOCS,
            FILECOLLECTIONNAME.PROJECTS,
            FILECOLLECTIONNAME.GOALS,
            FILECOLLECTIONNAME.IMPROVEMENTIDEAS,
            FILECOLLECTIONNAME.IMPROVEMENTACTIVITIES,
            FILECOLLECTIONNAME.ISSUES,
            FILECOLLECTIONNAME.ISSUESACCIDENTS].map(x => ({
                key: x,
                text: t(fileCollectionKeys[x]),
                value: getFileCollectionName(x),
            })),
        [t],
    )

    const [module, setModule] = useState<string>()
    const [companyId, setCompanyId] = useState<unknown>()
    const { files, loading, error, unDeleteFile } = useFilesAdmin(module, companyId)

    return (
        <>
            <Message info>
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <label>{t('module')}</label>
                            <Dropdown
                                options={moduleOptions}
                                value={module}
                                onChange={(_, d) => setModule(d.value as string)}
                                selection
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t('company')}</label>
                            <Dropdown
                                options={companyOptions}
                                value={companyId as string}
                                onChange={(_, d) => setCompanyId(d.value)}
                                selection
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Message>
            <Segment loading={loading}>
                <ErrorBox error={error} />

                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('file')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('created')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('collectionId')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('deleted')}</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {files?.map(x => (
                            <Table.Row key={x.id}>
                                <Table.Cell>
                                    <FileIcon file={x} />
                                    <FileLink
                                        module={module!}
                                        file={x}
                                    />
                                </Table.Cell>
                                <Table.Cell>{formatDate(x.created, 'Y-MM-DD HH:mm')}</Table.Cell>
                                <Table.Cell>{x.collectionId}</Table.Cell>
                                <Table.Cell>{formatDate(x.deleted, 'Y-MM-DD HH:mm')}</Table.Cell>
                                <Table.Cell>
                                    {x.deleted && (
                                        <ActionButton
                                            action={() => unDeleteFile(x.id)}
                                            content={t('restore')}
                                            size="mini"
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
        </>
    )
}

export default AdminFilesPage
