import React, { useEffect, useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal } from 'semantic-ui-react'
import { Course, CourseEvaluation, useCourseEvaluation } from '../../hooks/skills/course'
import ActionButton from '../common/actionButton'
import DynamicFields, {
    DynamicFieldsSpecification,
    DynamicFieldType,
} from '../common/dynamicFields'
import ErrorBox from '../common/errorBox'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'

export interface EvaulateCourseModalProps {
    course?: Course
}

const getDefault = (courseId: number) => ({
    id: courseId,
    heldDate: '',
    lessonsLearned: '',
    review: '',
    recommend: false,
})

const EvaulateCourseModal: React.FunctionComponent<EvaulateCourseModalProps> = ({ course }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [evaluation, setEvaluation] = useState<CourseEvaluation>(getDefault(0))
    const { evaluate, error } = useCourseEvaluation()

    useEffect(() => {
        setIsOpen(!!course)

        if (course) setEvaluation(getDefault(course.id))
    }, [course])

    const fields: DynamicFieldsSpecification = {
        dateCompleted: {
            type: DynamicFieldType.Date,
            required: true,
            value: evaluation?.dateCompleted,
        },
        review: {
            type: DynamicFieldType.Text,
            labelOverride: 'reviewCourseText',
            required: true,
            value: evaluation?.review,
        },
        lessonsLearned: {
            type: DynamicFieldType.Text,
            required: true,
            value: evaluation?.lessonsLearned,
        },
        satisfiedPercentage: {
            type: DynamicFieldType.Number,
            required: true,
            value: evaluation?.satisfiedPercentage,
        },
        recommend: {
            type: DynamicFieldType.Boolean,
            labelOverride: 'wouldRecommend',
            required: false,
            value: evaluation?.recommend,
        },
    }

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} closeOnDimmerClick={false}>
            <Modal.Header>{t('evaluateCourse')}</Modal.Header>
            <Modal.Content>
                <ErrorBox error={error} />
                <Form>
                    <DynamicFields
                        fields={fields}
                        onChange={(name, value) =>
                            setEvaluation(oldEvaluation => ({ ...oldEvaluation, [name]: value }))
                        }
                        translateFieldNames={true}
                        standaloneGrid={true}
                        columns={1}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    action={() => evaluate(course!.id, evaluation)}
                    postActionCallback={() => setIsOpen(false)}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={false}
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>

                <Button onClick={() => setIsOpen(false)} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EvaulateCourseModal
