import React from "react"
import { formatDate } from "../../../../actions/util"
import { Popup } from "semantic-ui-react"

interface IDateCellProps {
    date: string | Date | null

}

const DateCell = ({ date }: IDateCellProps) => {
    const dateContent = date && date !== "0001-01-01T00:00:00Z" ? formatDate(date) : ""
    return (
        <Popup
            content={dateContent}
            trigger={
                <span>
                    {dateContent}
                </span>
            }
        />

    )
}
export default DateCell
