import * as API from "../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../actions/error"
import { getState } from "../../../../state"

interface ISendSupplierLink {
    id: number
    confirmText: string
}

export function useSendSupplierLink() {
    const { t } = useTranslation()
    const { dispatch } = getState()
    const sendNotification = async (data: ISendSupplierLink) => {
        if (window.confirm(data.confirmText)) {
            return await API.suppliers.sendLink(data.id)
        }
    }

    const { isLoading: isSending, mutate: onNotify } = useMutation(sendNotification, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })

    return {
        isSending,
        onNotify,
    }
}