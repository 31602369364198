import React, { useState } from 'react'
import { ChevronDown, ChevronRight, Info, Link as LinkIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Card,
    Dimmer,
    Header,
    Icon,
    List,
    Loader,
    Message,
    Segment,
} from 'semantic-ui-react'
import { ChemicalRisk, useChemicals } from '../../hooks/chemicals'
import { guessIconFromName } from '../common/files'
import FlexBox from '../common/flexBox'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import PictogramSymbols from './pictogramSymbols'
import RiskAnalysisInfoModal from './riskAnalysisInfoModal'
import SafetySymbols from './safetySymbols'

const shortenUrl = (url: string, maxLength: number = 30) => {
    if (!url || url.length < maxLength) return url

    const glue = '....'
    const keepLength = maxLength / 2 - glue.length / 2

    return `${url.substring(0, keepLength)}${glue}${url.substring(url.length - keepLength)}`
}

const ChemicalInfo: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { data: chemicals, isValidating } = useChemicals()
    const [selectedRisk, setSelectedRisk] = useState<ChemicalRisk>()
    const [expandedChemical, setExpandedChemical] = useState<number>()

    return (
        <>
            <Dimmer active={isValidating && !chemicals?.length}>
                <Loader />
            </Dimmer>

            <RiskAnalysisInfoModal risk={selectedRisk} />

            {!isValidating && !chemicals?.length && (
                <Message
                    info
                    icon={<SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />}
                    content={t('noChemicals')}
                />
            )}

            {chemicals?.map(x => (
                <Card key={x.id} fluid>
                    <Card.Content>
                        <Card.Header
                            onClick={() =>
                                setExpandedChemical(expandedChemical === x.id ? undefined : x.id)
                            }
                        >
                            <FlexBox gap="15px">
                                <SemanticFeatherIcon
                                    size="24px"
                                    FeatherIcon={
                                        expandedChemical === x.id ? ChevronDown : ChevronRight
                                    }
                                />
                                {x.name}
                            </FlexBox>
                        </Card.Header>
                    </Card.Content>

                    {expandedChemical === x.id && (
                        <>
                            <Card.Content>
                                <List>
                                    {x.supplier && (
                                        <List.Item description={t('supplier')} content={x.supplier} />
                                    )}
                                    {x.usedFor && (
                                        <List.Item description={t('usedFor')} content={x.usedFor} />
                                    )}
                                    {(x.safetySheetUrl || !!x.uploadedSafetySheets.length) && (
                                        <List.Item
                                            description={t('safetySheet')}
                                            content={
                                                <List>
                                                    {x.safetySheetUrl && (
                                                        <List.Item>
                                                            <a
                                                                href={x.safetySheetUrl}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title={x.safetySheetUrl}
                                                            >
                                                                <LinkIcon size={14} />
                                                                {shortenUrl(x.safetySheetUrl, 40)}
                                                            </a>
                                                        </List.Item>
                                                    )}

                                                    {x.uploadedSafetySheets.map((s, i) => (
                                                        <List.Item key={i}>
                                                            <a
                                                                href={s.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title={s.url}
                                                            >
                                                                <Icon
                                                                    name={guessIconFromName(s.name)}
                                                                />
                                                                {s.name}
                                                            </a>
                                                        </List.Item>
                                                    ))}
                                                </List>
                                            }
                                        />
                                    )}
                                    {!!x.risks.length && (
                                        <List.Item
                                            description={t('riskAnalysis')}
                                            content={
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        setSelectedRisk({ ...x.risks[0] })
                                                    }
                                                    content={t('open')}
                                                    size="tiny"
                                                    style={{ marginTop: '5px' }}
                                                />
                                            }
                                        />
                                    )}
                                </List>
                            </Card.Content>

                            {(!!x.risks?.length || !!x.pictograms?.length) && (
                                <Card.Content>
                                    <SafetySymbols risk={x.risks?.[0]} imageSize="32px" />
                                    <PictogramSymbols
                                        pictogram={x.pictograms?.[0]}
                                        imageSize="32px"
                                    />
                                </Card.Content>
                            )}
                        </>
                    )}
                </Card>
            ))}
        </>
    )
}

export default ChemicalInfo
